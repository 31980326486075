<div class="section-container">
  <h2 class="section-title p-b-15">Import AKMTA Membership Renewal (Excel file)</h2>
  <div class="section-content">
      <p>Select an Excel file to import membership renewals:</p>
      <input (change)="addFile($event)" type="file" id="fileInput" class="file-input" accept=".xlsx, .xls" />
      <br>
      <button id="importButton" (click)="processFile()">UPLOAD FILE</button>
      <p id="statusMessage"></p>
      <button id="downloadSampleButton" (click)="downloadSample()" class="secondary-button">Download Sample Excel</button>
  </div>
</div>
import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import Helper from 'src/app/helper/Helper';

declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-video-manager',
  templateUrl: './video-manager.component.html',
  styleUrls: ['./video-manager.component.scss']
})
export class VideoManagerComponent implements OnInit {

  videos: Array<{ id: number, url: string, title: string, safeUrl?: SafeResourceUrl }> = [];
  displayDialog: boolean = false;
  isEditMode: boolean = false;
  currentVideo: { id?: number, url: string, title: string } = { url: '', title: '' };
  selectedVideoUrl: string | null = null; // For playing the selected video
  iframeLoaded: { [key: number]: boolean } = {};

  
  constructor(private sanitizer: DomSanitizer, private http: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder, protected appService: AppService) {
    this.loadVideos();
  }

  ngOnInit() {
    this.appService.setTitle("VIDEO MANAGER")
    this.appService.setMenuItems([{label:'SETTINGS',disabled:true,icon:"pi pi-cog"}, {label:'VIDEO MANAGER',disabled:true,icon:"pi pi-youtube"}])

  }

  loadVideos() {
    this.http.get<any[]>(`${environment.serverUrl}/videos`)
    .subscribe( (data:any) => {
      this.videos = data.data.map(video => ({
        ...video,
        safeUrl: this.getSafeUrl(video.url) // Create safe URLs only once
      }));
    });
  }

  openAddVideoDialog() {
    this.isEditMode = false;
    this.currentVideo = { url: '', title: '' };
    this.displayDialog = true;
  }

  openEditVideoDialog(video: any) {
    this.isEditMode = true;
    this.currentVideo = { ...video };
    this.displayDialog = true;
  }

  saveVideo() {
    if (this.isEditMode) {
      // Replace with your actual API endpoint to update a video
      this.http.put(`${environment.serverUrl}/videos/${this.currentVideo.id}`, this.currentVideo).subscribe(() => {
        this.loadVideos();
        this.displayDialog = false;
      });
    } else {
      // Replace with your actual API endpoint to add a video
      this.http.post(`${environment.serverUrl}/videos`, this.currentVideo).subscribe(() => {
        this.loadVideos();
        this.displayDialog = false;
      });
    }
  }

  deleteVideo(videoId: number) {
    // Replace with your actual API endpoint to delete a video
    Helper.showConfirmationAlert(`Are you sure?`,`You want to delete the selected video?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        this.http.delete(`${environment.serverUrl}/videos/${videoId}`).subscribe(() => this.loadVideos());
      }
    })
  }

  extractVideoId(url: string): string {
    const videoIdMatch = url.match(/(?:youtube\.com\/.*v=|youtu\.be\/)([^&?\/]+)/);
    return videoIdMatch ? videoIdMatch[1] : '';
  }

  playVideo(url: string) {
    this.selectedVideoUrl = url; // Set the selected video URL for the player
  }

  getSafeUrl(url: string): SafeResourceUrl {
    const videoId = this.extractVideoId(url);
    if (videoId) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`);
    }
    return '';
  }

  onIframeLoad(videoId: number) {
    this.iframeLoaded[videoId] = true;
  }
}

<div class="video-container p-30">
  <button pButton type="button" label="New Video" icon="pi pi-plus" class="m-r-30 new-video-button" (click)="openAddVideoDialog()"></button>
  <div class="video-grid p-t-45 p-b-45">
    <div class="video-card" *ngFor="let video of videos">
      <div class="iframe-wrapper">
        <iframe [src]="video.safeUrl" (load)="onIframeLoad(video.id)" loading="lazy" frameborder="0" allowfullscreen></iframe>
        <div class="spinner" *ngIf="!iframeLoaded[video.id]">Loading...</div>
      </div>
      <div class="video-overlay">
        <button pButton icon="pi pi-pencil" (click)="openEditVideoDialog(video)"></button>
        <button pButton icon="pi pi-trash" class="p-button-danger" (click)="deleteVideo(video.id)"></button>
      </div>
      <p style="padding-left: 10px;font-weight: 500;font-size: 14px;">{{ video.title }}</p>
    </div>
  </div>
</div>

<p-dialog header="{{ isEditMode ? 'Edit Video' : 'Add Video' }}" [(visible)]="displayDialog" [modal]="true" [closable]="false" [responsive]="true" [style]="{width: '400px'}">
  <div class="p-fluid">
    <div class="p-field">
      <label for="videoUrl">YouTube Video URL</label>
      <input id="videoUrl" type="text" pInputText [(ngModel)]="currentVideo.url" placeholder="Enter YouTube URL">
    </div>
    <div class="p-field">
      <label for="videoTitle">Title</label>
      <input id="videoTitle" type="text" pInputText [(ngModel)]="currentVideo.title" placeholder="Enter Title">
    </div>
  </div>
  <p-footer>
    <button pButton label="Save" icon="pi pi-check" class="btn btn-primary m-r-5" (click)="saveVideo()"></button>
    <button pButton label="Cancel" icon="pi pi-times" class="btn btn-primary m-r-5" (click)="displayDialog = false"></button>
  </p-footer>
</p-dialog>

import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-list-all-donations',
  templateUrl: './list-all-donations.component.html',
  styleUrls: ['./list-all-donations.component.scss']
})
export class ListAllDonationsComponent implements OnInit {

  @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  @ViewChild('dt') dataTable: Table;

  moment = moment
  environment = environment
  
  allDonations: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  
  donationTypes : any[] = []
  unitDonationTypes: any[] = [];
  units: any[] = [];
  filteredUnits = []
  paymentTypes: any[] = []
  viewDonationDialog = false
  selectedDonation = undefined
  selectedDonationAttachments : any[] = []
  selectedAttachment = undefined
  donationForm: FormGroup = undefined; // Define the form group
  filteredMembers = []
  unitMembers = []
  editing = false

  totalAmountReceived = 0

  protected fileUploads : MultipleFileUpload = new MultipleFileUpload()

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder, private appService: AppService) { }

  ngOnInit() {

    this.appService.setTitle("VIEW ALL DONATIONS")
    this.appService.setMenuItems([{label:'ALL DONATIONS', routerLink: '/donations',icon:"pi pi-wallet", disabled:true}])

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/donations/unit-donation-types/all`),
      this.httpClient.get<any>(`${environment.serverUrl}/units/all`),
     ]).pipe(
      map(([unitDonationTypes, units]) => { 
        this.unitDonationTypes = unitDonationTypes.data
        this.units = units.data
      })
    ))

    this.donationTypes = [
      { label: 'Member Donation', value: 'MemberDonation' },
      { label: 'Unit Donation', value: 'UnitDonation' },
      { label: 'Others', value: 'Others' },
    ];
    
    this.paymentTypes = [
      { label: 'Cash', id: 'Cash' },
      { label: 'Credit Card', id: 'CreditCard' },
      { label: 'Debit Card', id: 'DebitCard' },
      { label: 'UPI', id: 'UPI' },
      { label: 'PayTM', id: 'PayTM' },
      { label: 'Bank Transfer', id: 'BankTransfer' },
      
    ];
  }

  loadDonations(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'DESC' : 'ASC', // Sorting direction
    };

    // Apply filters dynamically if they exist
    if (event.filters.donationType && event.filters.donationType.value) {
      params.donationType = event.filters.donationType.value;
    }
    if (event.filters.unitName && event.filters.unitName.value) {
      params.units = event.filters.unitName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.name && event.filters.name.value) {
      params.memberName = event.filters.name.value;
    }
    if (event.filters.otherDonatorName && event.filters.otherDonatorName.value) {
      params.otherDonatorName = event.filters.otherDonatorName.value;
    }
    if (event.filters.unitDonationType && event.filters.unitDonationType.value) {
      params.unitDonationTypeId = event.filters.unitDonationType.value;
    }
    

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/donations/`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allDonations = result.data;
        this.allDonations.map (mem =>{
          
        })
        this.totalRecords = result.count;
        this.totalAmountReceived = result.totalAmountReceived
        
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  addNewDonation(){
    this.editing = false
    this.fileUploads = new MultipleFileUpload()
    this.selectedDonation = undefined
    this.selectedDonationAttachments = []
    this.donationForm = this.donationForm = this.fb.group({
      donationType: [null,Validators.required ],
      amountPaid: [null, [Validators.required, Validators.min(1)]],
      paymentDate: [null,Validators.required],
      paymentReferenceNumber: [null,],
      paymentType: [null, ],
      otherDonatorName: [''],
      member: [''],
      memberSelected: [''],
      unitDonationTypeId: [''],
      unitId: [''],
      unitName: [''],
      memberDonations: this.fb.array([]) // Array for member contributions
    });
    this.viewDonationDialog = true
    
  }

  get memberDonations() {
    return this.donationForm.get('memberDonations') as FormArray;
  }

  onDonationTypeChange() {
    const type = this.donationForm.get('donationType')?.value;
    if (type === "UnitDonation") {
      
    }
    // Validation logic for other types...
  }

  onRowSelect(event: any) {
    this.viewTransaction(event.data)
  }

  async loadMembersForUnit(unitId: number) {

    this.memberDonations.clear(); // Clear existing entries in FormArray
    const params: any = {
      take: 100, // Number of records to take
      skip: 0, // Starting point
      order: 'name', // Sorting field
      orderDir: 'ASC', // Sorting direction
    };

    params.units = [unitId]
    const httpParams = new HttpParams({ fromObject: params });
    
    this.httpClient.get(`${environment.serverUrl}/members`, { params: httpParams }).subscribe(
      (result:any) => {
        this.unitMembers = result.data;
        this.unitMembers.forEach(member => {
          const memberForm = this.fb.group({
            memberId: [member.id],
            memberName: [member.name], // Only for display purposes
            amountPaid: [0]
          });
          memberForm.get('amountPaid')?.valueChanges.subscribe(() => {
            this.updateTotalAmountPaid();
          });
          this.memberDonations.push(memberForm)
        });
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching members:', error);
        this.loading = false;
      }
    );
  }

  viewTransaction(donation){
    this.editing = true
    this.selectedDonation = donation
    this.donationForm = this.fb.group({
      donationType: [this.selectedDonation.donationType === "Member Donation" ? "MemberDonation" : (this.selectedDonation.donationType === "Unit Donation" ? "UnitDonation" : "Others"),Validators.required ],
      amountPaid: [this.selectedDonation.amountPaid, [Validators.required, Validators.min(1)]],
      paymentDate: [this.selectedDonation.paymentDate ? new Date(this.selectedDonation.paymentDate) : undefined,Validators.required],
      paymentReferenceNumber: [this.selectedDonation.paymentReferenceNumber,],
      paymentType: [this.selectedDonation.paymentType, null, ],
      otherDonatorName: [this.selectedDonation.otherDonatorName],
      member: [this.selectedDonation.donatedByMember],
      memberSelected: [true],
      unitDonationTypeId: [this.selectedDonation?.unitDonationType?.id],
      unitId: [this.selectedDonation.unit?.id],
      unitName: [this.selectedDonation.unit?.name],
      memberDonations: this.fb.array([]) // Array for member contributions
    })
    this.memberDonations.clear(); // Clear existing entries in FormArray
    this.selectedDonation.donatedByUnitMembers.forEach(memberDonation => {
      const memberForm = this.fb.group({
        memberId: [memberDonation.member.id],
        memberName: [memberDonation.member.name], // Only for display purposes
        amountPaid: [memberDonation.amountPaid]
      })
      memberForm.get('amountPaid')?.valueChanges.subscribe(() => {
        this.updateTotalAmountPaid();
      });
      this.memberDonations.push(memberForm);
    });
    this.updateTotalAmountPaid();
    this.fileUploads = new MultipleFileUpload()
    this.selectedDonationAttachments = this.selectedDonation.attachments
    this.viewDonationDialog = true

  }

  saveDonation(){

    if (!this.donationForm.valid) {
      this.donationForm.markAllAsTouched();
      this.focusFirstInvalidControl()
      Helper.showMessageAlert('Error',`Please fill in all the mandatory fields before saving.`,'error');
      return;
    }

    const formData = this.getFormData()
    
    this.httpClient.post(`${environment.serverUrl}/donations`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','Donation details added successfully!','success');
          this.viewDonationDialog = false
          this.loadDonations(this.dataTable.createLazyLoadMetadata())
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });
  }

  onUnitSelected(e){
    this.donationForm.patchValue({
      unitId: e.value.uuid, 
      unitName: e.value.name, 
      unitNumber:e.value.unitNumber,
      unitSecretary: e.value.secretary?.name, 
    });
    this.loadMembersForUnit(e.value.uuid);
  }
  filterUnits(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.units.length; i++) {
      let unit = this.units[i];
      if (unit.name.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(unit);
      }
    }

    this.filteredUnits = filtered;
  }

  onMemberSelected(e){
    this.donationForm.patchValue({
      member: e.value,
      unitName: e.value?.unit ? e.value?.unit.name : "",
      memberSelected: true
    });
  }

  filterMembers(event) {

    let query = event.query;
    const params: any = {
      take: 10, // Number of records to take
      skip: 0, // Starting point
      order: 'name', // Sorting field
      orderDir: 'ASC', // Sorting direction
    };
    params.name = query.toLowerCase();
    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/members`, {params: httpParams},).subscribe({
      next: (result:any) => {
        this.filteredMembers = result.data
      },
      error: (e) => {
        console.error(e);
        Helper.processError(e);
      },
      complete: () => console.info('complete')
    });
  }

  getFormData(){
    const formData: FormData = new FormData();    
    for (const field in this.donationForm.controls) { 
      const control = this.donationForm.get(field);
      if( control.value && control.value != null){
        if( field === "member"){
          formData.append("memberId",control.value.id)
          continue
        }
        else if( field === "payoutEvent"){
          formData.append("payoutEventId",control.value.id)
          continue
        }
        else if( field === "memberDonations"){
          continue
        }

        formData.append(field,control.value)
      }
    }
    
    if( this.selectedDonation && this.selectedDonation.uuid ){
      formData.append("donationUUID",this.selectedDonation.uuid)
    }
    const memberDonationsArray = this.donationForm.get('memberDonations') as FormArray;
    memberDonationsArray.controls.forEach((memberDonation) => {
      formData.append('memberDonations', JSON.stringify({
        memberId: memberDonation.get('memberId')?.value,
        amountPaid: memberDonation.get('amountPaid')?.value
      }));
    });
    
    if( this.selectedDonation && this.selectedDonation.uuid ){
      formData.append("payoutUUID",this.selectedDonation.uuid)
    }

    if( this.fileUploads && this.fileUploads.attachments.length > 0 ){
      this.fileUploads.attachments.forEach((file) => { formData.append('donationAttachments', file); });
    }
    return formData
  }

  updateTotalAmountPaid() {
    const totalAmountPaid = this.memberDonations.controls.reduce((total, control) => {
      const amount = control.get('amountPaid')?.value || 0;
      return total + amount;
    }, 0);

    this.donationForm.patchValue({
      amountPaid: totalAmountPaid, 
    })
    
  }


  refreshList(){
    if (this.dataTable) {
      this.loadDonations(this.dataTable.createLazyLoadMetadata());
   }
  }
  
  getBackgroundColor(payment){
    if( payment.status === 'Paid'){
      return '#009688'
    }
    else if( payment.status === 'Requested'){
      return '#BA8E23'
    }
    else if( payment.status === 'Declined'){
      return '#FF0000'
    }
  }

  getColor(payment){
    if( payment.status === 'Paid'){
      return '#FFF'
    }
    else if( payment.status === 'Requested'){
      return '#FFF'
    }
    else if( payment.status === 'Declined'){
      return '#FFF'
    }
  }

  getImageUrlForAttachment(attachment){
    return `${environment.serverUrl}/file/download?path=${attachment.objectName}`
  }

  openImage(attachment){
    this.selectedAttachment = attachment
    const modalRef = this.modalService.open(this.imageTemplateRef, {backdropClass: 'light-blue-backdrop', centered:true,size:'xl',keyboard:true});
  }

    getTimestamp(){
    return Date.now()
  }

  private focusFirstInvalidControl() {
    const formControls = this.donationForm.controls;

    // Iterate through the controls using Object.keys
    for (const key of Object.keys(formControls)) {
      const control = this.donationForm.get(key);

      // If the control is invalid, find the corresponding input element and focus it
      if (control?.invalid) {
        const invalidControl = this.formFields.find((element) => {
          const inputElement = element.nativeElement.querySelector('input');
          // Check if the input element's name matches the key or fallback to nativeElement.name
          return inputElement?.name === key || element.nativeElement.name === key;
        });

        if (invalidControl) {
          const inputElement = invalidControl.nativeElement.querySelector('input');

          // Focus the input element if it exists, otherwise fallback to the native element
          if (inputElement) {
            inputElement.focus();
          } else {
            invalidControl.nativeElement.focus();
          }
        }
        break; // Focus only on the first invalid control
      }
    }
  }

  downloadAttachment(attachment){
    this.httpClient.get(this.getImageUrlForAttachment(attachment), { responseType: 'blob' })
    .subscribe({
      next: (blob) => {
        saveAs(blob, attachment.originalFileName); // Triggers the "Save As" dialog
      },
      error: (error: HttpErrorResponse) => {
        console.error('Download failed:', error);
      }
    } )
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
  removeServerImage(attachment){
    Swal.fire({
      title: 'Are you sure you want to delete the attachment?',
      text: "Remember: This action cannot be reversed,",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ff7c92',
      confirmButtonText: 'Yes',
      cancelButtonText:'No'
    }).then((result) => {
      if( result.value ){
        let params = {"path":attachment.objectName}
        this.httpClient.delete(`${environment.serverUrl}/donations/${this.selectedDonation.uuid}/attachments?attachmentId=${attachment.uuid}`)
        .subscribe({
          next: (result) =>{
            this.selectedDonationAttachments = this.selectedDonationAttachments.filter( object => object.id !== attachment.id )
            this.loadDonations(this.dataTable.createLazyLoadMetadata())
          },
          error:(err) =>{
            Helper.processError(err)
          }
        })
      }
    })
  }

  removeAttachmentImage(i){
    this.fileUploads.attachments.splice(i,1)
    this.fileUploads.progressInfos.splice(i,1)
    this.fileUploads.previews.splice(i,1)
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async addDocuments(event){
    if (event.target.files.length === 0)
      return;
    
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      
      const reader = new FileReader();
      var that = this

      reader.onload = (function(file){
        return function(e){
          that.fileUploads.attachments.push(file);
          that.fileUploads.progressInfos.push( { value: 0, fileName: file.name,size:Helper.humanFileSize(file.size), type:'' } );
          that.fileUploads.previews.push(e.target.result);
        }
      })(event.target.files[i])

      reader.readAsDataURL(event.target.files[i]);
    }
  }
}

<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright" style="text-align: end;">
      <a style="padding-right:20px;" href="#" (click)="showTermsAndConditions($event);">Terms & Conditions</a>
      <a style="padding-right:20px;" href="#" (click)="showPrivacyPolicy($event)">Privacy Policy</a>
      <a style="padding-right:20px;" href="#" (click)="showRefundAndCancellations($event)">Refund & Cancellations</a>
    </div>
  </div>
</div>

<p-dialog header="Terms & Conditions" class="terms-conditions" [(visible)]="displayTermsAndConditions" [modal]="true" [closable]="true" [style]="{width: '50vw'}">
  <div>
    <h3>Introduction</h3>
    <p style="color:#000;">Welcome to the All Kerala Marble And Tiles Workers Welfare Society (AKMTWWS).
      By joining as a member, accessing our services, or making payments, you agree to be bound
      by the following terms and conditions. Please read them carefully.</p>
    <ul style="padding:10px;">
      <h3>Membership</h3>
       <li style="padding:10px;">Eligibility: Membership is open to individuals who meet the society’s eligibility
        criteria. AKMTWWS reserves the right to approve or deny membership applications
        based on our internal guidelines.</li>
       <li style="padding:10px;">Membership Fees: All members are required to pay a one-time joining fee and an
        annual membership fee as specified by AKMTWWS.</li>
         <li style="padding:10px;">Termination of Membership: AKMTWWS reserves the right to terminate the
          membership of any member who violates the society’s code of conduct or fails to
          meet membership requirements.</li>
    </ul>
    <ul style="padding:10px;">
      <h3>Payments and Fees</h3>
       <li style="padding:10px;">Payment of Fees: Members are required to pay all dues, including membership fees,
        subscriptions, and other applicable charges, on or before the specified due dates.</li>
       <li style="padding:10px;">Refunds and Cancellations: Our refund and cancellation policies are outlined in our
        Refund and Cancellation Policy. By making a payment, members agree to the terms
        of that policy.</li>
         <li style="padding:10px;">Late Fees and Penalties: Late payments may incur additional fees or penalties.
          Failure to pay dues may result in suspension of membership privileges until dues are
          paid in full.</li>
    </ul>
    <ul style="padding:10px;">
      <h3>Use of Services</h3>
       <li style="padding:10px;">Access to Welfare Services: Members are eligible for welfare benefits and services
        as outlined in society policies. These benefits are contingent upon timely payment of
        dues and compliance with society guidelines.</li>
       <li style="padding:10px;">Event Participation: Members may participate in society events and activities.
        Event-specific terms and conditions will be provided at the time of registration.</li>
         <li style="padding:10px;">Code of Conduct: Members are expected to conduct themselves professionally and
          respectfully during society events, meetings, and in interactions with other members.
          Violations of the code of conduct may result in disciplinary actions.</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Privacy and Data Protection</h3>
       <li style="padding:10px;">Personal Information: We collect personal information for processing membership
        applications, payments, and providing services. Our Privacy Policy details how we
        handle and protect your information.</li>
       <li style="padding:10px;">Data Security: We employ security measures to protect member information.
        However, members are responsible for safeguarding their login credentials and
        notifying AKMTWWS immediately if any unauthorized access is suspected.</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Limitation of Liability</h3>
      <p style="color:#000;">AKMTWWS shall not be liable for any direct, indirect, incidental, or consequential damages
        arising from:</p>
       <li style="padding:10px;">Unauthorized access or alteration of member data.</li>
       <li style="padding:10px;">Actions or omissions of third parties providing services on behalf of AKMTWWS,
        including but not limited to payment processing services.</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Amendments to Terms</h3>
      <p style="color:#000;">AKMTWWS reserves the right to update these Terms &amp; Conditions at any time. Members
        will be notified of significant changes, and continued membership or use of services will
        constitute acceptance of any revised terms.</p>
    </ul>

    <ul style="padding:10px;">
      <h3>Governing Law</h3>
      <p style="color:#000;">These Terms &amp; Conditions are governed by the laws of India. Any disputes arising out of or
        in connection with these terms shall be subject to the exclusive jurisdiction of the courts in
        Thrissur, Kerala.</p>
    </ul>
  </div>
</p-dialog>

<p-dialog header="Privacy Policy" [(visible)]="displayPrivacyPolicy" [modal]="true" [closable]="true" [style]="{width: '50vw'}">
  <div>
    <h3>Introduction</h3>
    <p style="color:#000;">The All Kerala Marble And Tiles Workers Welfare Society (AKMTWWS) values your
      privacy and is committed to safeguarding the personal and financial information of our
      members and users. This Privacy Policy outlines our practices regarding the collection, use,
      and disclosure of personal information in connection with membership services, payments,
      and other activities. It is designed to comply with applicable laws and to support our access to
      payment processing services.</p>
    
      <ul style="padding:10px;">
        <h3>Information We Collect</h3>
        <p style="color:#000;">To facilitate membership services and process payments securely, AKMTWWS may collect
          the following types of information:</p>
         <li style="padding:10px;">Personal Identification Information: Name, contact details (such as mobile number
          and email address), date of birth, and membership number.</li>
         <li style="padding:10px;">Payment Information: Bank account details, UPI ID, payment method, and
          transaction history.</li>
           <li style="padding:10px;">Professional Information: Occupation, qualifications, and work nature.</li>
           <li style="padding:10px;">Health and Welfare Information: Details regarding welfare eligibility, medical
            assistance needs, and any provided health documentation.</li>
      </ul>

      <ul style="padding:10px;">
        <h3>How We Use Your Information</h3>
        <p style="color:#000;">We use the collected information for purposes including:</p>
         <li style="padding:10px;">Payment Processing: To facilitate membership fees, welfare fund contributions, and
          other dues through secure third-party payment gateways.</li>
         <li style="padding:10px;">Payment Processing: To facilitate membership fees, welfare fund contributions, and
        other dues through secure third-party payment gateways.</li>
         <li style="padding:10px;">Improvement of Services: To analyze member interactions to enhance our offerings
        and improve overall service quality.</li>
         <li style="padding:10px;">Legal Compliance: To comply with applicable laws, regulations, and requests from
        regulatory authorities.</li>
      </ul>

      <ul style="padding:10px;">
        <h3>How We Protect Your Information</h3>
        <p style="color:#000;">AKMTWWS implements industry-standard measures to protect your personal and financial
          information. These measures include:</p>
         <li style="padding:10px;">Data Encryption: All sensitive data, including payment information, is encrypted
          during transmission and storage.</li>
         <li style="padding:10px;">Restricted Access: Only authorized personnel have access to personal data, and they
          are trained on data security practices..</li>
         <li style="padding:10px;">Secure Payment Gateway: We partner with secure, PCI-compliant payment
          gateways to process payments..</li>
      </ul>

      <ul style="padding:10px;">
        <h3>Sharing Your Information</h3>
        <p style="color:#000;">We may share your information with:</p>
         <li style="padding:10px;">Authorized Third Parties: Trusted payment gateways and service providers who
          help us process payments securely.</li>
         <li style="padding:10px;">Legal Authorities: In response to a legal requirement, we may share information with
          law enforcement or regulatory bodies as required by law.</li>
      </ul>

      <ul style="padding:10px;">
        <h3>Your Rights and Choices</h3>
        <p style="color:#000;">As a member, you have the right to:</p>
         <li style="padding:10px;">Access and Update: Request access to or corrections of your personal information.</li>
         <li style="padding:10px;">Withdraw Consent: Opt out of certain uses or disclosures of your information, as
          allowed by law.</li>
         <li style="padding:10px;">Data Portability: Request a copy of your personal data in a portable format.</li>
      </ul>

      <ul style="padding:10px;">
        <h3>Contact Us</h3>
        <p style="color:#000;">For any questions regarding this Privacy Policy or your data rights, please contact us at:</p>
        <p style="color:#000;">All Kerala Marble And Tiles Workers Welfare Society (AKMTWWS)
          Phone: 0487-2953338
          Email: privacy&#64;akmtwws.com</p>
      </ul>
      
  </div>
</p-dialog>

<p-dialog header="Refund & Cancellations" [(visible)]="displayRefundAndCancellations" [modal]="true" [closable]="true" [style]="{width: '50vw'}">
  <div>
    <h3>Introduction</h3>
    <p style="color:#000;">At the All Kerala Marble And Tiles Workers Welfare Society (AKMTWWS), we are
      committed to providing our members with a transparent and fair refund and cancellation
      process. This policy outlines the circumstances under which refunds and cancellations may
      be granted and details the steps required to request them.</p>
    <ul style="padding:10px;">
      <p style="color:#000;">Membership Fees and Donations</p>
       <li style="padding:10px;">Membership Fees: Membership fees collected by AKMTWWS are typically non-
        refundable, as they contribute to the welfare fund and operational activities that
        support our members.</li>
       <li style="padding:10px;">Donations: Donations made to AKMTWWS are voluntary contributions and are
        generally non-refundable. In exceptional cases, requests for refunds may be reviewed
        on a case-by-case basis.</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Refund Eligibility</h3>
      <p style="color:#000;">AKMTWWS will consider refund requests under the following circumstances:</p>
       <li style="padding:10px;">Duplicate Payment: If a duplicate payment is made due to a technical error or
        oversight, members may be eligible for a full refund of the duplicate amount.</li>
       <li style="padding:10px;">Payment Errors: Refunds may be issued in the event of a confirmed payment
        processing error that results in an incorrect charge.</li>
      <p style="color:#000;">Refunds will only be processed if the request meets the conditions outlined and is submitted
        within the specified time frame.</p>
    </ul>

    <ul style="padding:10px;">
      <h3>Refund Request Process</h3>
      <p style="color:#000;">To request a refund, members should follow these steps:</p>
       <li style="padding:10px;">Submit a Request: Email our support team at refunds&#64;akmtwws.com with the
        subject line &quot;Refund Request&quot;. Include the membership number, payment receipt,
        date of payment, and reason for the request.</li>
       <li style="padding:10px;">Verification: AKMTWWS will review the request and verify the payment details and
        eligibility.</li>
       <li style="padding:10px;">Approval and Processing: If approved, refunds will be processed within 15 working
        days. Refunds will be issued to the original payment method unless otherwise
        arranged.</li>
      <p style="color:#000;">Please note that AKMTWWS reserves the right to deny refund requests that do not meet the
        criteria set out in this policy.</p>
    </ul>

    <ul style="padding:10px;">
      <h3>Cancellation Policy</h3>
       <li style="padding:10px;">Membership Cancellations: Members may choose to cancel their membership by
        submitting a written request to our office. Membership fees are non-refundable upon
        cancellation.</li>
       <li style="padding:10px;">Event Registrations: If AKMTWWS organizes any member events or activities
        requiring pre-registration, cancellations may be eligible for a refund if requested at
        least 7 days before the event date.</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Non-Refundable Transactions</h3>
      <p style="color:#000;">The following transactions are non-refundable:</p>
       <li style="padding:10px;">Membership fees already processed</li>
       <li style="padding:10px;">Membership fees already processed</li>
       <li style="padding:10px;">Late fees, penalties, or fines related to the membership</li>
    </ul>

    <ul style="padding:10px;">
      <h3>Changes to this Policy</h3>
      <p style="color:#000;">AKMTWWS reserves the right to update or modify this Refund and Cancellation Policy at
        any time. Members are encouraged to review this policy periodically for any changes.</p>
    </ul>

    <ul style="padding:10px;">
      <h3>Contact Us</h3>
      <p style="color:#000;">For questions or to request a refund, please contact us:</p>
      <p style="color:#000;">All Kerala Marble And Tiles Workers Welfare Society (AKMTWWS)
        Phone: 0487-2953338
        Email: refunds&#64;akmtwws.com</p>
    </ul>
  </div>
</p-dialog>
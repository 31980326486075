import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  displayTermsAndConditions: boolean = false;
  displayPrivacyPolicy: boolean = false;
  displayRefundAndCancellations: boolean = false;

  showTermsAndConditions(event: Event) {
    event.preventDefault();
    this.displayTermsAndConditions = true;
  }

  showPrivacyPolicy(event: Event) {
    event.preventDefault();
    this.displayPrivacyPolicy = true;
  }

  showRefundAndCancellations(event: Event) {
    event.preventDefault();
    this.displayRefundAndCancellations = true;
  }
}

import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-list-school-going-children',
  templateUrl: './list-school-going-children.component.html',
  styleUrls: ['./list-school-going-children.component.css']
})
export class ListSchoolGoingChildrenComponent implements OnInit {

  @ViewChild('dt') dataTable: Table;

  allChildren: any[] = [];
  totalRecords: number = 0;

  standardTypes = [
    {
      label : "Pre Primary", value: "PrePrimary",
    },
    {
      label : "Lower Primary", value: "LowerPrimary",
    },
    {
      label : "Upper Primary", value: "UpperPrimary",
    },
    {
      label : "High School", value: "HighSchool", 
    },
    {
      label : "XI / XII", value: "PostHighSchool", 
    }
  ]

  statuses = [
    { label: 'Without Unit', value: 'NoUnit' },
    { label: 'Active', value: 'Active' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Deleted Not Approved', value: 'SoftDeleted' },
    { label: 'Deleted', value: 'Deleted' },
    { label: 'Deceased Not Approved', value: 'SoftDeceased' },
    { label: 'Deceased', value: 'Deceased' }
  ];

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder,protected appService: AppService) { }

  ngOnInit() {
    
    this.appService.setTitle("REPORTS - SCHOOL GOING CHILDREN")
    this.appService.setMenuItems([{label:'REPORTS',disabled:true,icon:"pi pi-print"}, {label:'SCHOOL GOING CHILDREN',disabled:true,icon:"pi pi-print"}])

  }

  loadData(event: any) {

    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'DESC' : 'ASC', // Sorting direction
    }
    if (event.filters.name && event.filters.name.value) {
      params.name = event.filters.name.value;
    }
    if (event.filters.standardType && event.filters.standardType.value) {
      params.standardType = event.filters.standardType.value.value
    }
    if (event.filters.parentName && event.filters.parentName.value) {
      params.parentName = event.filters.parentName.value;
    }
    if (event.filters.status && event.filters.status.value) {
      params.status = event.filters.status.value;
    }
    
    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/reports/children/`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allChildren = result.data;
        this.totalRecords = result.count;
        
      },
      (error) => {
        console.error('Error fetching Unit Donation Types:', error);
      }
    );
  }

  refreshList(){
    if (this.dataTable) {
      this.loadData(this.dataTable.createLazyLoadMetadata());
   }
  }

  getQualification(qualification){
    switch(qualification){
      case 'LKG': return "LKG"
      case 'UKG': return "UKG"
      case '1': return "IST STANDARD"
      case '2': return "II ND STANDARD"
      case '3': return "III RD STANDARD"
      case '4': return "IV TH STANDARD"
      case '5': return "V TH STANDARD"
      case '6': return "VI TH STANDARD"
      case '7': return "VII TH STANDARD"
      case '8': return "VIII TH STANDARD"
      case '9': return "IX TH STANDARD"
      case '10': return "X TH STANDARD"
      case '11': return "XI TH STANDARD"
      case '12': return "XII TH STANDARD"
      case 'OTHERS': return "OTHERS"

    }
  }

  getSeverity(status: string) {

    switch (status) {
      case 'Without Unit':
        return 'warning';
      case 'Active':
        return 'success';
      case 'Suspended':
        return 'info';
      case 'Deleted Not Approved':
        return 'danger';
      case 'Deleted':
        return 'danger';
      case 'Deceased Not Approved':
        return 'danger';
      case 'Deceased':
        return "danger";
    }
}
}

<div class="customizer-links" [ngClass]="{'open' : customizer }" id="inner-customizer">
   <div class="nav flex-column nac-pills" id="c-pills-tab" role="tablist" aria-orientation="vertical">
      <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': customizer == 'color' }"
         id="c-pills-profile-tab" (click)="openCustomizerSetting('color')">
         <div class="settings color-settings"> <i class="icofont icofont-color-bucket"></i> </div>
      </a>
   </div>
</div>
<div class="customizer-contain" [ngClass]="{'open' : customizer }">
   <div class="tab-content" id="c-pills-tabContent">
      <div class="customizer-body custom-scrollbar">
         <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'settings' }" id="c-pills-home"
            role="tabpanel" aria-labelledby="c-pills-home-tab">
            <h6>Layout Type</h6>
            <ul class="main-layout layout-grid">
               <li data-attr="ltr" [ngClass]="{'active': layoutType == 'ltr'}" (click)="customizeLayoutType('ltr')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-light body"> <span class="badge badge-dark">LTR Layout</span> </li>
                     </ul>
                  </div>
               </li>
               <li data-attr="rtl" [ngClass]="{'active': layoutType == 'rtl'}" (click)="customizeLayoutType('rtl')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-light body"> <span class="badge badge-dark">RTL Layout</span> </li>
                        <li class="bg-dark sidebar"></li>
                     </ul>
                  </div>
               </li>
            </ul>
            <h6 class="">Sidebar Type</h6>
            <ul class="sidebar-type layout-grid">
               <li data-attr="normal-sidebar" [ngClass]="{'active': sidebarType == 'default'}"
                  (click)="customizeSidebarType('default')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li data-attr="compact-sidebar" [ngClass]="{'active': sidebarType == 'compact'}"
                  (click)="customizeSidebarType('compact')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar compact"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
               <li data-attr="compact-icon-sidebar" [ngClass]="{'active': sidebarType == 'compact-icon'}"
                  (click)="customizeSidebarType('compact-icon')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body">
                     <ul>
                        <li class="bg-dark sidebar compact-icon"></li>
                        <li class="bg-light body"> </li>
                     </ul>
                  </div>
               </li>
            </ul>
            <h6 class="">Sidebar settings</h6>
            <ul class="sidebar-setting layout-grid">
               <li data-attr="default-sidebar"
                  [ngClass]="{'active': customize.data.settings.sidebar_setting == 'default-sidebar'}"
                  (click)="customizeSidebarSetting('default-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-dark">Default</span> </div>
               </li>
               <li data-attr="border-sidebar"
                  [ngClass]="{'active': customize.data.settings.sidebar_setting == 'border-sidebar'}"
                  (click)="customizeSidebarSetting('border-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-dark">Border</span> </div>
               </li>
               <li data-attr="iconcolor-sidebar"
                  [ngClass]="{'active': customize.data.settings.sidebar_setting == 'iconcolor-sidebar'}"
                  (click)="customizeSidebarSetting('iconcolor-sidebar')">
                  <div class="header bg-light">
                     <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                     </ul>
                  </div>
                  <div class="body bg-light"> <span class="badge badge-dark">icon Color</span> </div>
               </li>
            </ul>


         </div>
         <div class="tab-pane fade" [ngClass]="{'active show': customizer == 'color' }" id="c-pills-profile"
            role="tabpanel" aria-labelledby="c-pills-profile-tab">
            <h6>Layout Colors</h6>
            <ul class="layout-grid customizer-color">
               <li class="color-layout" data-attr="light-1" data-primary="#4466f2" data-secondary="#1ea6ec"
                  (click)="customizeLightColorScheme('color-1')">
                  <div></div>
               </li>
               <li class="color-layout" data-attr="light-2" data-primary="#0288d1" data-secondary="#26c6da"
                  (click)="customizeLightColorScheme('color-2')">
                  <div></div>
               </li>
               <li class="color-layout" data-attr="light-3" data-primary="#8e24aa" data-secondary="#ff6e40"
                  (click)="customizeLightColorScheme('color-3')">
                  <div></div>
               </li>
               <li class="color-layout" data-attr="light-4" data-primary="#4c2fbf" data-secondary="#2e9de4"
                  (click)="customizeLightColorScheme('color-4')">
                  <div></div>
               </li>
               <li class="color-layout" data-attr="light-5" data-primary="#7c4dff" data-secondary="#7b1fa2"
                  (click)="customizeLightColorScheme('color-5')">
                  <div></div>
               </li>
               <li class="color-layout" data-attr="light-6" data-primary="#3949ab" data-secondary="#4fc3f7"
                  (click)="customizeLightColorScheme('color-6')">
                  <div></div>
               </li>
            </ul>
            <h6 class="">Sidebar background setting</h6>
            <ul class="nav nac-pills" id="pills-tab" role="tablist">
               <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': sidebarSetting == 'color' }"
                     id="pills-home-tab" (click)="customizerSetting('color')">Color</a>
               </li>
               <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link"
                     [ngClass]="{'active show': sidebarSetting == 'pattern' }" id="pills-profile-tab"
                     (click)="customizerSetting('pattern')">Pattern</a>
               </li>
               <li class="nav-item">
                  <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active show': sidebarSetting == 'image' }"
                     id="pills-contact-tab" (click)="customizerSetting('image')">image</a>
               </li>
            </ul>
            <div class="tab-content sidebar-main-bg-setting" id="pills-tabContent">
               <div class="tab-pane fade" [ngClass]="{'active show': sidebarSetting == 'color' }" id="pills-home"
                  role="tabpanel" aria-labelledby="pills-home-tab">
                  <ul class="sidebar-bg-settings">
                     <li class="bg-dark" data-attr="dark-sidebar" (click)="customizeSidebarBackround('dark-sidebar')">
                     </li>
                     <li class="bg-white" data-attr="light-sidebar"
                        (click)="customizeSidebarBackround('light-sidebar')"> </li>
                     <li class="bg-color1" data-attr="color1-sidebar"
                        (click)="customizeSidebarBackround('color1-sidebar')"> </li>
                     <li class="bg-color2" data-attr="color2-sidebar"
                        (click)="customizeSidebarBackround('color2-sidebar')"> </li>
                     <li class="bg-color3" data-attr="color3-sidebar"
                        (click)="customizeSidebarBackround('color3-sidebar')"> </li>
                     <li class="bg-color4" data-attr="color4-sidebar"
                        (click)="customizeSidebarBackround('color4-sidebar')"> </li>
                     <li class="bg-color5" data-attr="color5-sidebar"
                        (click)="customizeSidebarBackround('color5-sidebar')"> </li>
                  </ul>
               </div>
               <div class="tab-pane fade" [ngClass]="{'active show': sidebarSetting == 'pattern' }" id="pills-profile"
                  role="tabpanel" aria-labelledby="pills-profile-tab">
                  <ul class="sidebar-bg-settings">
                     <li class="bg-pattern1" data-attr="sidebar-pattern1"
                        (click)="customizeSidebarBackround('sidebar-pattern1')"> </li>
                     <li class="bg-pattern2" data-attr="sidebar-pattern2"
                        (click)="customizeSidebarBackround('sidebar-pattern2')"> </li>
                     <li class="bg-pattern3" data-attr="sidebar-pattern3"
                        (click)="customizeSidebarBackround('sidebar-pattern3')"> </li>
                     <li class="bg-pattern4" data-attr="sidebar-pattern4"
                        (click)="customizeSidebarBackround('sidebar-pattern4')"> </li>
                     <li class="bg-pattern5" data-attr="sidebar-pattern5"
                        (click)="customizeSidebarBackround('sidebar-pattern5')"> </li>
                     <li class="bg-pattern6" data-attr="sidebar-pattern6"
                        (click)="customizeSidebarBackround('sidebar-pattern6')"> </li>
                  </ul>
               </div>
               <div class="tab-pane fade" [ngClass]="{'active show': sidebarSetting == 'image' }" id="pills-contact"
                  role="tabpanel" aria-labelledby="pills-contact-tab">
                  <ul class="sidebar-bg-settings">
                     <li class="bg-img1" data-attr="sidebar-img1" (click)="customizeSidebarBackround('sidebar-img1')">
                     </li>
                     <li class="bg-img2" data-attr="sidebar-img2" (click)="customizeSidebarBackround('sidebar-img2')">
                     </li>
                     <li class="bg-img3" data-attr="sidebar-img3" (click)="customizeSidebarBackround('sidebar-img3')">
                     </li>
                     <li class="bg-img4" data-attr="sidebar-img4" (click)="customizeSidebarBackround('sidebar-img4')">
                     </li>
                     <li class="bg-img5" data-attr="sidebar-img5" (click)="customizeSidebarBackround('sidebar-img5')">
                     </li>
                     <li class="bg-img6" data-attr="sidebar-img6" (click)="customizeSidebarBackround('sidebar-img6')">
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table'
import { ToolbarModule } from 'primeng/toolbar'
import { ButtonModule } from 'primeng/button'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RippleModule } from 'primeng/ripple';
import { AvatarModule } from 'primeng/avatar';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { SharedModule } from '../../shared/shared.module';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TabViewModule } from 'primeng/tabview';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ListAllUnitsComponent } from './list-all-units/list-all-units.component'
import { ViewUnitComponent } from './view-unit/view-unit.component'
import { ListPayoutEventComponent } from './list-payout-event/list-payout-event.component'
import { ListUnitDonationTypesComponent } from './list-unit-donation-types/list-unit-donation-types.component'
import { UtilitiesComponent } from './utilities/utilities.component'
import { VideoManagerComponent } from './video-manager/video-manager.component'
import { PrimeIcons, MenuItem } from 'primeng/api';

@NgModule({
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    TableModule,
    ToolbarModule,
    ButtonModule,
    RippleModule,
    MultiSelectModule,
    AvatarModule,
    TagModule,
    DropdownModule,
    AvatarGroupModule,
    InputTextModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    DividerModule,
    InputNumberModule,
    CardModule,
    MessagesModule,
    InputGroupModule,
    InputGroupAddonModule,
    AutoCompleteModule,
    DialogModule,
    TabViewModule,
    SplitButtonModule,
    FormsModule,
    PaginatorModule,
    InputMaskModule,
    ReactiveFormsModule,
    SelectButtonModule,
    SharedModule
  ],
  declarations: [ListAllUnitsComponent,ViewUnitComponent, ListPayoutEventComponent,ListUnitDonationTypesComponent, UtilitiesComponent, VideoManagerComponent]
})
export class LookupDataModule { }

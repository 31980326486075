<p-table
  #dt
  [value]="members"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalRecords"
  selectionMode="single"
  [(selection)]="selectedMember" 
  [lazy]="true"
  [globalFilterFields]="['name', 'mobileNumber', 'unit.name', 'constituency.name','constituency.district.name']"
  [reorderableColumns]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [showCurrentPageReport]="true"
  [loading]="loading"
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadMembers($event)"
  [expandedRowKeys]="expandedRows" 
  (onRowExpand)="onRowExpand($event)"
  (onRowSelect)="onRowSelect($event)" 
  (onRowUnselect)="onRowUnselect($event)" 
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>

<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
      <p-button pRipple class="btn-rounded p-button-success" severity="success" label="New Member" icon="pi pi-plus" text (onClick)="addNewMember()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn pSortableColumn="name">Name <p-sortIcon field="name" /></th>
      <th pFrozenColumn pSortableColumn="mobileNumber">Mobile Number <p-sortIcon field="mobileNumber" /></th>
      <th pSortableColumn="membershipNumber">Membership Number<p-sortIcon field="membershipNumber" /></th>
      <th pSortableColumn="natureOfWork">Core Skill<p-sortIcon field="natureOfWork" /></th>
      <th pSortableColumn="unit.name" [style.width]="'270px'">Unit Name<p-sortIcon field="unit.name" /></th>
      <th pSortableColumn="constituency.name">Mandalam<p-sortIcon field="constituency.name" /></th>
      <th pSortableColumn="constituency.district.name">District<p-sortIcon field="constituency.district.name" /></th>
      <th  [style.width]="'80px'">Joined</th>
      <th pSortableColumn="status">Status<p-sortIcon field="status" /></th>
      <th>Pension By</th>
      <th style="text-align:right">Total Deposit Paid</th>
      <th style="text-align:right">Total Subscription Paid</th>
      <th style="text-align:right">Total Amount Paid</th>
      <th style="text-align:right">Total Weeks</th>
      <th style="text-align:center"></th>
    </tr>
    <tr>
      <th></th>
      <th>
          <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="name" 
          placeholder="Search by name" 
          ariaLabel="Filter Name" />
      </th>
      <th>
          <p-columnFilter 
            matchMode="in"
            [showMenu]="false"
            type="text" 
            field="mobileNumber" 
            placeholder="Search by mobile number" 
            ariaLabel="Filter mobile number" />
      </th>
      
      <th>
        <p-columnFilter 
          matchMode="in"
          [showMenu]="false"
          type="text" 
          field="membershipNumber" 
          placeholder="Search by membership number" 
          ariaLabel="Filter membership number" />
      </th>
      <th>
        <p-columnFilter *ngIf="natureOfWorks && natureOfWorks.length > 0" field="natureOfWork" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  [ngModel]="value" 
                  [options]="natureOfWorks" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="label"
                  appendTo="body"
                  >
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.label }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter *ngIf="units && units.length > 0" field="unitName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  [ngModel]="value" 
                  [options]="units" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  appendTo="body"
                  >
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter *ngIf="constituencies && constituencies.length > 0" field="constituencyName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  [ngModel]="value" 
                  [options]="constituencies" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  appendTo="body">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter *ngIf="districts && districts.length > 0" field="districtName" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect 
                  [ngModel]="value" 
                  [options]="districts" 
                  placeholder="Any" 
                  (onChange)="filter($event.value)" 
                  optionLabel="name"
                  appendTo="body">
                      <ng-template let-option pTemplate="item">
                          <div class="inline-block vertical-align-middle">
                              <span class="ml-1 mt-1">{{ option.name }}</span>
                          </div>
                      </ng-template>
              </p-multiSelect>
          </ng-template>
        </p-columnFilter>
      </th>
      <th  [style.width]="'80px'"></th>
      <th>
        <p-columnFilter field="status" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown 
                    [ngModel]="value" 
                    [options]="statuses"
                    (onChange)="filter($event.value)" 
                    placeholder="Select One" 
                    [showClear]="true"
                    appendTo="body">
                        <ng-template let-option pTemplate="item">
                            <p-tag 
                                [value]="option.label" 
                                [severity]="getSeverity(option.label)" />
                        </ng-template>
                </p-dropdown>
            </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="pensionByYear" matchMode="equals" [showMenu]="false">
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown 
                  [ngModel]="value" 
                  [options]="yearsFilter"
                  (onChange)="filter($event.value)" 
                  placeholder="Select One" 
                  [showClear]="true"
                  optionLabel="name"
                  appendTo="body">
              </p-dropdown>
          </ng-template>
      </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-member let-expanded="expanded">
    <tr [pSelectableRow]="member"  [style.color]="getRowColor(member)">
      <td pFrozenColumn><p-avatar 
        image="{{environment.serverUrl}}/members/{{member.uuid}}/profile-image" 
        styleClass="mr-2" 
        size="xlarge" 
        shape="circle" /></td>
      <td pFrozenColumn>{{ member.name }}</td>
      <td pFrozenColumn>{{ member.mobileNumber }}</td>
      <td>{{ member.membershipNumber }}</td>
      <td>{{ member.natureOfWork }}</td>
      <td [style.width]="'270px'">{{ member.unit?.name }}</td>
      <td>{{ member.constituency?.name }}</td>
      <td>{{ member.constituency?.district?.name }}</td>
      <td [style.width]="'80px'">{{ member.dateJoined ? (member.dateJoined | date: 'dd MMM yyyy'):"" }}</td>
      <td><p-tag 
        [value]="member.status" 
        [severity]="getSeverity(member.status)" /></td>
      <td>{{calculatePensionEligibilityYear(member)}}</td>
      <td style="text-align:right">{{ member.totalDepositPaid | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ member.totalSubscriptionPaid | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ member.totalAmountPaid | currency:'INR':'symbol'}}</td>
      <td style="text-align:center">{{ member.totalWeeks }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <button 
              pButton 
              pRipple 
              type="button" 
              icon="pi pi-pencil" 
              (click)="onRowEditInit(member)" 
              class="p-button-rounded p-button-text">
          </button>
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no members matching your search criteria</td>
    </tr>
  </ng-template>

  <!-- Expanded Row Template -->
  <ng-template pTemplate="rowexpansion" let-member>
    <tr style="background: #f0f0f0;">
      <td colspan="3"></td>
      <td colspan="9">
          <div class="p-3">
            <p-table [value]="member.payments" dataKey="id">
              <ng-template pTemplate="header">
                <tr>
                  <th>Week #</th>
                  <th>Payment Date</th>
                  <th style="text-align:right">Deposit Amount</th>
                  <th style="text-align:right">Subscription Amount</th>
                  <th style="text-align:right">Total Amount</th>
                  <th>Status</th>
                  <th>Unit</th>
                  <th>Paid Date</th>
                  <th>Payment ID</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-payment>
                <tr>
                  <td>{{ payment.weekNumber }}</td>
                  <td>{{ payment.societyUnitPayment.societyPayment.paymentDate | date: 'dd MMM yyyy'}}</td>
                  <td style="text-align:right">{{ payment.depositAmount | currency:'INR':'symbol' }}</td>
                  <td style="text-align:right">{{ payment.weeklySubscriptionAmount | currency:'INR':'symbol' }}</td>
                  <td style="text-align:right">{{ payment.totalAmount | currency:'INR':'symbol' }}</td>
                  <td>{{ payment.status }}</td>
                  <td>{{ payment?.unitMemberPayment?.unitPayment?.unit?.name }}</td>
                  <td>{{ payment?.unitMemberPayment?.unitPayment?.paymentDate ? (payment?.unitMemberPayment?.unitPayment?.paymentDate | date: 'dd MMM yyyy') : "N/A"}}</td>
                  <td>{{ payment?.unitMemberPayment?.unitPayment?.transactionReferenceNumber ? payment?.unitMemberPayment?.unitPayment?.transactionReferenceNumber : "N/A"}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
      </td>
      <td colspan="3"></td>
    </tr>
  </ng-template>
</p-table>
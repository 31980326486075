<div *ngIf="!memberForm" style="width:100%;height:calc(100vh - 205px);background-color: #fff;justify-content: center;align-items: center;display: flex;">
  <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
</div>
<div *ngIf="memberForm" class="mt-4 p-l-45 p-r-45 ng-star-inserted">
  <p-tabView>
    <p-tabPanel header="GENERAL INFORMATION">
      <p-toolbar>
        <div class="p-toolbar-group-start">
            
        </div>
        <div *ngIf="editing" class="p-toolbar-group-center" [style.color]="getColorForStatus()">
            <strong>{{member ? member?.status.toUpperCase() : ''}}</strong>
        </div>
        <div class="p-toolbar-group-end">
          <p-splitButton *ngIf="editing && printMenuItems.length > 0" #up (onClick)="spltBtnClick(up, $event)" class="m-r-5" label="Print" [model]="printMenuItems" />
          <p-splitButton *ngIf="editing && botServicesMenuItems.length > 0" #up (onClick)="spltBtnClick(up, $event)" class="m-r-5" label="BOT Services" [model]="botServicesMenuItems" />
          <p-splitButton *ngIf="editing && statusMenuItems.length > 0" #ua (onClick)="spltBtnClick(ua, $event)" class="m-r-5" label="Change Status" [model]="statusMenuItems" />
          <button pButton type="submit" [raised]="true" (click)="save()" class="btn btn-primary">Save Member Details</button>
        </div>
    </p-toolbar>
      <form [formGroup]="memberForm" (ngSubmit)="save()">
        <div class="row">
          <div class="col-md-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <h5 class="p-t-5">ASSOCIATION & SOCIETY INFO</h5>
                <p>
                  Tell us about their relationship with the association and society
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="associationMembershipNumber">AKMTWA Membership No. (Association)</label><span class="text-danger">*</span>
                      <input #formField pInputText id="associationMembershipNumber" type="text" class="form-control-prime" formControlName="associationMembershipNumber" name="associationMembershipNumber" />
                      <div *ngIf="memberForm.controls.associationMembershipNumber.invalid && (memberForm.controls.associationMembershipNumber.dirty || memberForm.controls.associationMembershipNumber.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.associationMembershipNumber.errors.required">
                          AKMTWA Membership No. is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="membershipNumber">AKMTWWS Membership No.</label><span class="text-danger">*</span>
                      <input #formField pInputText id="membershipNumber" type="text" class="form-control-prime" formControlName="membershipNumber" name="membershipNumber" />
                      <div *ngIf="memberForm.controls.membershipNumber.invalid && (memberForm.controls.membershipNumber.dirty || memberForm.controls.membershipNumber.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.membershipNumber.errors.required">
                          AKMTWWS Membership No. is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="associationJoinedDate">Association Joined Date</label><span class="text-danger">*</span>
                      <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="associationJoinedDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="associationJoinedDate" (onSelect)="onChangeAssociationDateJoined($event)"/>
                      <div *ngIf="memberForm.controls.associationJoinedDate.invalid && (memberForm.controls.associationJoinedDate.dirty || memberForm.controls.associationJoinedDate.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.associationJoinedDate.errors.required">
                          Association Joined Date is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="membershipNumber">Association Mem. Expiry Date</label><span class="text-danger">*</span>
                      <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="associationMembershipExpirydDate" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="associationMembershipExpirydDate" />
                      <div *ngIf="memberForm.controls.associationMembershipExpirydDate.invalid && (memberForm.controls.associationMembershipExpirydDate.dirty || memberForm.controls.associationMembershipExpirydDate.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.associationMembershipExpirydDate.errors.required">
                          AKMTWWS Membership Expiry Date is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="associationJoiningFees">Association Joining Fees</label>
                      <p-inputNumber inputId="associationJoiningFees" class="form-control-prime" formControlName="associationJoiningFees" mode="decimal" [min]="0" />
                      <div *ngIf="memberForm.controls.associationJoiningFees.invalid && (memberForm.controls.associationJoiningFees.dirty || memberForm.controls.associationJoiningFees.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.associationJoiningFees.errors.required">
                          Association Joining Fees is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="yearsOfMembership">Years Of Membership in the Association</label>
                      <p-inputNumber [readonly]="true" inputId="yearsOfMembership" class="form-control-prime" formControlName="yearsOfMembership" mode="decimal" [min]="0"  [max]="100" [showButtons]="true" />
                    </div>
                  </div>
                </div>
                
                <ng-container *ngIf="member && member.retirementDetails">
                  <p-divider />
        
                  <h5 class="p-t-10" style="color:red">RESIGNATION DETAILS</h5>
                  <p>
                    Reason for Resignation from the society
                  </p>
                  <div class="p-20" style="border: 1px solid #F0F0F0">
                    <table class="benefits-table">
                      <tr class="service">
                        <td><label for="name">Date Of Resignation</label></td>
                        <td class="tableitem">{{ moment(member.retirementDetails.dateOfResignation).format('DD MMM YYYY') }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Reason For Resignation</label></td>
                        <td style="color:red" class="tableitem">{{ member.retirementDetails.reasonForResignation }}</td>
                      </tr>
                      <tr *ngIf="member.retirementDetails.reasonForResignation === 'Deceased'" class="service">
                        <td><label for="name">Accident</label></td>
                        <td style="color:red" class="tableitem">{{ member.retirementDetails.accident ? "YES" : "NO" }}</td>
                      </tr>
                      <tr class="service">
                        <td><p-divider /></td>
                        <td class="tableitem"><p-divider /></td>
                      </tr>
                      <tr *ngIf="member.retirementDetails.reasonForResignation === 'Deceased'" class="service">
                        <td><label for="name">Retirement Benefit Amount</label></td>
                        <td class="tableitem">{{ member.retirementDetails?.benefitAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Total Deposit Amount</label></td>
                        <td class="tableitem">{{ member.retirementDetails?.depositAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Total Subscription Paid</label></td>
                        <td class="tableitem">{{ member.retirementDetails?.subscriptionPaid.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Service Charges</label></td>
                        <td class="tableitem">{{ member.retirementDetails?.feeAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Other Deductions</label></td>
                        <td class="tableitem">{{ member.retirementDetails?.anyOtherDeductions.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><p-divider /></td>
                        <td class="tableitem"><p-divider /></td>
                      </tr>
                      <tr class="service">
                        <td><label for="name" style="font-weight: 500;">Total Amount</label></td>
                        <td class="tableitem" style="font-weight: 500;">{{ member.retirementDetails?.totalAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
                      </tr>
                      <tr class="service">
                        <td><label for="name">Payment Type</label></td>
                        <td class="tableitem">{{ member.retirementDetails.paymentType }}</td>
                      </tr>

                      <tr class="service">
                        <td><label for="name">Payment Reference No.</label></td>
                        <td class="tableitem">{{ member.retirementDetails.paymentReferenceNumber }}</td>
                      </tr>
                      
                    </table>
                    <p-messages [value]="[{ severity: 'success', summary: member.retirementDetails.message }]" [enableService]="false" [closable]="false" />
                    <div class="d-flex justify-content-end">
                      <button *ngIf="showConfirmButton()" type="button" pButton severity="success" [raised]="true" (click)="confirmStatusUpdate()" class="btn btn-primary">{{member?.status === "Soft Deleted" ? 'Confirm Expulsion' : (member?.status === "Soft Retired") ? "Confirm Retirement" : "Confirm Deceased" }}</button>
                    </div>

                  </div>
                </ng-container>
                
                <p-divider />
      
                <h5 class="p-t-10">UNIT DETAILS</h5>
                <p>
                  Select the Unit where the member belongs
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="unitName">Unit Name</label>
                      <div class="d-flex align-items-center">
                        <p-autoComplete [readonly]="editing"  id="unitName" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="unitName" [suggestions]="filteredUnits" (onSelect)="onUnitSelected($event)" (completeMethod)="filterUnits($event)" field="name" [dropdown]="!editing" class="form-control-prime">
                          <ng-template let-city pTemplate="item">
                              <div class="city-item">
                              <div>{{city.name}}</div>
                              </div>
                          </ng-template>
                        </p-autoComplete>
                        <a *ngIf="editing" href="javascript:void(0)" (click)="editUnit()" class="ms-2" style="text-decoration: underline; cursor: pointer;">Edit</a>
                      </div>
                    </div>
                    <!-- Unit Name -->
                    <div class="col-md-4 mb-3">
                      <label for="unitName">Unit No.</label>
                      <input pInputText id="unitNumber" type="text" class="form-control-prime" formControlName="unitNumber" />
                    </div>
      
                    <!-- Unit Number -->
                    <div class="col-md-4 mb-3">
                      <label for="unitNumber">Unit Secretary</label>
                      <input pInputText id="unitSecretary" type="text" class="form-control-prime" formControlName="unitSecretary" />
                    </div>
                  </div>
                </div>
                <p-divider />
      
                <h5 class="p-t-5">MEMBER PERSONAL INFO</h5>
                <p>
                  Personal details about the member
                </p>
                <p-divider />
                
                <div class="p-l-10 p-r-10">
                  <div style="position: absolute;right: 50px;" class="m-form__group">
                    <input #attachments id="productImage" type="file" (change)="addImage($event)" style="display: none;" accept="image/*"/>
                    <div *ngIf="profileImageAvailable && !useImageSrc" class="file-asset">
                        <img style="max-width:250px;width:250px;" src="{{environment.serverUrl}}/members/{{member.uuid}}/profile-image?{{getTimestamp()}}" alt="Product Image" />
                        <div class="remove-file" (click)="removeImage()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                    </div>
                    <div *ngIf="profileImageAvailable && useImageSrc" class="file-asset">
                      <img style="max-width:250px;width:250px;" [src]="imageSrc" alt="Product Image" />
                      <div class="remove-file" (click)="removeImage()"><a href="javascript:void(0)"><i style="font-size: 22px;color:#ff5370 !important" class="fa fa-trash-o"></i></a></div>
                  </div>
                    <div *ngIf="!profileImageAvailable" class="file-asset">
                      <img style="max-width:250px" src="/assets/images/user-profile-placeholder.jpg" alt="Product Image" />
                    </div>
                    <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" style="width:250px" onclick="document.getElementById('productImage').click();">
                      <i class="p-r-10 fa fa-plus"></i>
                      <span *ngIf="profileImageAvailable">Update Member Photo</span>
                      <span *ngIf="!profileImageAvailable">Add Member Photo</span>
                    </button>
                  </div>
                  <div class="row">
                    <!-- Name -->
                    <div class="col-md-6 mb-3">
                      <label for="name">Name</label><span class="text-danger">*</span>
                      <input #formField pInputText id="name" type="text" class="form-control-prime" formControlName="name" name="name" />
                      <div *ngIf="memberForm.controls.name.invalid && (memberForm.controls.name.dirty || memberForm.controls.name.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.name.errors.required">
                          Name is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="aadharNumber">Aadhar No.</label><span class="text-danger">*</span>
                      <p-inputMask #formField mask="9999-9999-9999" placeholder="Eg: 1000-1000-1000" inputId="aadharNumber" class="form-control-prime" formControlName="aadharNumber" name="aadharNumber" />
                      <div *ngIf="memberForm.controls.aadharNumber.invalid && (memberForm.controls.aadharNumber.dirty || memberForm.controls.aadharNumber.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.aadharNumber.errors.required">
                          Aadhar Number is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <!-- Address -->
                    <div class="col-md-6 mb-3">
                      <label for="address">Address</label><span class="text-danger">*</span>
                      <textarea #formField id="address" rows="5" cols="30" pInputTextarea class="form-control-prime" formControlName="address" name="address"></textarea>
                      <div *ngIf="memberForm.controls.address.invalid && (memberForm.controls.address.dirty || memberForm.controls.address.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.address.errors.required">
                          Address is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="constituency">Constituency</label>
                      <p-autoComplete id="constituencyName" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" formControlName="constituencyName" [suggestions]="filteredConstituencies" (onSelect)="onConstituencySelected($event)" (completeMethod)="filterConstituency($event)" field="name" [dropdown]="true" class="form-control-prime">
                        <ng-template let-city pTemplate="item">
                            <div class="city-item">
                            <div>{{city.name}}</div>
                            </div>
                        </ng-template>
                      </p-autoComplete>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="district">District</label>
                      <input pInputText id="district" type="text" class="form-control-prime" formControlName="district" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="dateJoined">Date of Joining</label><span class="text-danger">*</span>
                      <p-calendar #formField class="form-control-prime" readonlyInput=true  formControlName="dateJoined" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" name="dateJoined" />
                      <div *ngIf="memberForm.controls.dateJoined.invalid && (memberForm.controls.dateJoined.dirty || memberForm.controls.dateJoined.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.dateJoined.errors.required">
                          Date Joined is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="dateOfBirth">Date of Birth</label>
                      <p-calendar (onSelect)="onSelectDateOfBirth($event)" readonlyInput=true class="form-control-prime" formControlName="dateOfBirth" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="age">Age</label>
                      <input pInputText id="age" type="text" class="form-control-prime" formControlName="age" />
                    </div>
                  </div>
      
                  <div class="row">
                    <!-- Phone Number -->
                    <div class="col-md-4 mb-3">
                      <label for="mobileNumber">Phone Number</label><span class="text-danger">*</span>
                      <p-inputGroup>
                        <p-inputGroupAddon>+91</p-inputGroupAddon>
                        <p-inputMask #formField mask="9999999999" placeholder="Eg: 9895250939" inputId="mobileNumber" class="form-control-prime" formControlName="mobileNumber" name="mobileNumber" />
                      </p-inputGroup>
                      <div *ngIf="memberForm.controls.mobileNumber.invalid && (memberForm.controls.mobileNumber.dirty || memberForm.controls.mobileNumber.touched)" class="text text-danger">
                        <div *ngIf="memberForm.controls.mobileNumber.errors.required">
                          Phone Number is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="natureOfWork">Nature of work</label>
                      <p-dropdown id="natureOfWork" [options]="natureOfWorks" formControlName="natureOfWork" class="form-control-prime" appendTo="body"></p-dropdown>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="experience">Experience (in years)</label>
                      <p-inputNumber inputId="experience" class="form-control-prime" formControlName="experience" mode="decimal" [min]="0"  [max]="60" [showButtons]="true" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="welfareFundMembershipNumber">Jeevanidhi Membership No.</label>
                      <input pInputText id="welfareFundMembershipNumber" type="text" class="form-control-prime" formControlName="welfareFundMembershipNumber" />
                    </div>
                    
                    <!-- Blood Group -->
                    <div class="col-md-4 mb-3">
                      <label for="bloodGroup">Blood Group</label>
                      <p-dropdown id="bloodGroup" [options]="bloodGroups" formControlName="bloodGroup" class="form-control-prime" appendTo="body"></p-dropdown>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="memberCategory">Category</label>
                      <p-dropdown id="memberCategory" [options]="categories" formControlName="memberCategory" class="form-control-prime" appendTo="body"></p-dropdown>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="willingnessToDonateOrgans">Willing to Donate Organs</label>
                      <p-checkbox inputId="willingnessToDonateOrgans" binary="true" class="form-control-prime" formControlName="willingnessToDonateOrgans" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="willingnessToDonateBlood">Willing to donate blood</label>
                      <p-checkbox inputId="willingnessToDonateBlood" binary="true" class="form-control-prime" formControlName="willingnessToDonateBlood" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="willingnessToBePartOfSocialServices">Willing to be part of social services</label>
                      <p-checkbox inputId="willingnessToBePartOfSocialServices" binary="true" class="form-control-prime" formControlName="willingnessToBePartOfSocialServices" />
                    </div>
                  </div>
                </div>
                <p-divider />
      
                <h5 class="p-t-5">BANK DETAILS</h5>
                <p>
                  Tell us about their bank details
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="upiId">UPI ID</label>
                      <input pInputText id="upiId" type="text" class="form-control-prime" formControlName="upiId" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="bankAccountNumber">Bank Account Number</label>
                      <input pInputText id="bankAccountNumber" type="text" class="form-control-prime" formControlName="bankAccountNumber" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="ifscCode">IFSC Code</label>
                      <input pInputText id="ifscCode" type="text" class="form-control-prime" formControlName="ifscCode" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="branchName">Bank Branch</label>
                      <input pInputText id="branchName" type="text" class="form-control-prime" formControlName="branchName" />
                    </div>
                  </div>
                </div>
      
                <p-divider />
      
                <h5 class="p-t-5">FAMILY / NOMINEE DETAILS</h5>
                <p>
                  Tell us about their family / nominee details
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="married">Married</label>
                      <p-checkbox inputId="married" binary="true" class="form-control-prime" formControlName="married" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="spouseName">Spouse Name</label>
                      <input pInputText id="spouseName" type="text" class="form-control-prime" formControlName="spouseName" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="nomineeName">Nominee Name</label>
                      <input pInputText id="nomineeName" type="text" class="form-control-prime" formControlName="nomineeName" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="aadharNumber">Nominee's Aadhar No.</label>
                      <p-inputMask mask="9999-9999-9999" placeholder="Eg: 1000-1000-1000" inputId="nomineesAadharNumber" class="form-control-prime" formControlName="nomineesAadharNumber" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="nomineesWorkNature">Nominee's Job</label>
                      <input pInputText id="nomineesWorkNature" type="text" class="form-control-prime" formControlName="nomineesWorkNature" />
                    </div>
      
                    <div class="col-md-4 mb-3">
                      <label for="nomineesDateOfBirth">Nominee's Date of Birth</label>
                      <p-calendar class="form-control-prime" readonlyInput=true formControlName="nomineesDateOfBirth" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="nomineeBankAccountNumber">Nominee's Bank Account Number</label>
                      <input pInputText id="nomineeBankAccountNumber" type="text" class="form-control-prime" formControlName="nomineeBankAccountNumber" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="nomineeIfscCode">Nominee's IFSC Code</label>
                      <input pInputText id="nomineeIfscCode" type="text" class="form-control-prime" formControlName="nomineeIfscCode" />
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="nomineeBranchName">Nominee's Bank Branch</label>
                      <input pInputText id="nomineeBranchName" type="text" class="form-control-prime" formControlName="nomineeBranchName" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="hasChildrenExcelledInArtsOrSports">Have Children Excelling In Arts Or Sports</label>
                      <p-checkbox inputId="hasChildrenExcelledInArtsOrSports" binary="true" class="form-control-prime" formControlName="hasChildrenExcelledInArtsOrSports" />
                    </div>
        
                    <!-- Other Family Details -->
                    <div class="col-md-4 mb-3">
                      <label for="detailsOfArtsOrSports">If so, furnish the details</label>
                      <textarea id="detailsOfArtsOrSports" rows="2" cols="30" pInputTextarea class="form-control-prime" formControlName="detailsOfArtsOrSports"></textarea>
                    </div>
                    <div class="col-md-4 mb-3">
                      <label for="grade">Grade (if any)</label>
                      <input pInputText id="grade" type="text" class="form-control-prime" formControlName="grade" />
                    </div>
                  </div>
                </div>
      
                <p-divider />
      
                <h5 class="p-t-5">FAMILY MEMBERS</h5>
                <p>
                  Tell us about their family members
                </p>
                <p-divider />
      
                <div class="p-l-10 p-r-10">
      
                  <p-table styleClass="p-datatable-gridlines"
                      dataKey="dummyId"
                      [value]="familyMembers" [paginator]="false" [showCurrentPageReport]="true" [tableStyle]="{'min-width': '50rem'}">
                      <ng-template pTemplate="caption">
                        <div class="flex flex-wrap justify-content-end gap-2">
                            <button pButton pRipple (click)="addNewFamilyMember();$event.preventDefault()" class="btn btn-primary" [raised]="true">Add New Member</button>
                        </div>
                      </ng-template>
      
                      <ng-template pTemplate="header">
                          <tr>
                              <th style="width:15%">Name</th>
                              <th style="width:15%;text-align: right;">Age</th>
                              <th style="width:15%">Relation?</th>
                              <th style="width:25%">Qualification</th>
                              <th style="width:5%"></th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-familyMember>
                          <tr>
                              <td style="padding: 0 1rem;">{{familyMember.name}}</td>
                              <td style="padding: 0 1rem;text-align: right;">{{familyMember.age}}</td>
                              <td style="padding: 0 1rem;">{{familyMember.relation}}</td>
                              <td style="padding: 0 1rem;">{{getQualification(familyMember.educationalQualification)}}</td>
                              <td style="padding: 0.5rem 1rem;display: flex;">
                                <p-button styleClass="no-background" icon="pi pi-pencil" [rounded]="true" [outlined]="true" severity="success" (click)="editFamilyMember(familyMember);$event.preventDefault()"></p-button>
                                <p-button styleClass="no-background" icon="pi pi-trash" severity="danger" [rounded]="true" [outlined]="true" (click)="deleteFamilyMember(familyMember);$event.preventDefault()" />
                              </td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr  style="height:100px">
                            <td colspan="11" style="text-align: center;">Add family members by clicking on the `Add New Member` button</td>
                        </tr>
                      </ng-template>
                  </p-table>
                  
                </div>
      
                <p-divider />
                
                <h5 class="p-t-5">HEALTH DETAILS</h5>
                <p>
                  Tell us about their and their family members health condition
                </p>
                <p-divider />
      
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="hasVisionHearingOrOtherIssues">Vision / Hearing impaired</label>
                      <p-checkbox (onChange)="onChangeVisionHearingImpaired($event)" inputId="hasVisionHearingOrOtherIssues" binary="true" class="form-control-prime" formControlName="hasVisionHearingOrOtherIssues" />
                    </div>
        
                    <div class="col-md-8 mb-3">
                      <label for="detailsOfIllness">If so, furnish the details</label>
                      <textarea id="address" rows="2" cols="30" pInputTextarea class="form-control-prime" formControlName="detailsOfIllness"></textarea>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="hasBedriddenFamilyMembers">Have Bedridden Family Members</label>
                      <p-checkbox (onChange)="onChangeBedRiddenFamilyMembers()" inputId="hasBedriddenFamilyMembers" binary="true" class="form-control-prime" formControlName="hasBedriddenFamilyMembers" />
                    </div>
        
                    <div class="col-md-8 mb-3">
                      <label for="detailsOfBedRiddenFamilyMembers">If so, furnish the details</label>
                      <textarea id="address" rows="2" cols="30" pInputTextarea class="form-control-prime" formControlName="detailsOfBedRiddenFamilyMembers"></textarea>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="hasFamilyMembersUnderMentalStress">Have Family Members Under Mental Stress</label>
                      <p-checkbox (onChange)="onChangeFamilyMembersUndermentalStress()" inputId="hasFamilyMembersUnderMentalStress" binary="true" class="form-control-prime" formControlName="hasFamilyMembersUnderMentalStress" />
                    </div>
        
                    <div class="col-md-8 mb-3">
                      <label for="detailsOfFamilyMembersUnderMentalStress">If so, furnish the details</label>
                      <textarea id="address" rows="2" cols="30" pInputTextarea class="form-control-prime" formControlName="detailsOfFamilyMembersUnderMentalStress"></textarea>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="hasFamilyMembersWithCancerOrTerminalIllness">Have Family Members With Terminal Illness</label>
                      <p-checkbox (onChange)="onChangeOtherIllness()" inputId="hasFamilyMembersWithCancerOrTerminalIllness" binary="true" class="form-control-prime" formControlName="hasFamilyMembersWithCancerOrTerminalIllness" />
                    </div>
        
                    <div class="col-md-8 mb-3">
                      <label for="detailsOfOtherIllness">If so, furnish the details</label>
                      <textarea id="address" rows="2" cols="30" pInputTextarea class="form-control-prime" formControlName="detailsOfOtherIllness"></textarea>
                    </div>
                  </div>
  
                </div>
      
                <p-divider />
                
                <h5 class="p-t-5">PROPERTY DETAILS</h5>
                <p>
                  Tell us about the properties they own
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="ownsLand">Owns Land?</label>
                      <p-checkbox (onChange)="onChangeOwnsLand($event)" inputId="ownsLand" binary="true" class="form-control-prime" formControlName="ownsLand" />
                    </div>
      
                    <!-- Spouse Date of Birth -->
                    <div class="col-md-4 mb-3">
                      <label for="extendOfLand">Extend of Land</label>
                      <input pInputText id="extendOfLand" type="text" class="form-control-prime" formControlName="extendOfLand" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="ownsHouse">Owns House?</label>
                      <p-checkbox (onChange)="onChangeOwnsHouse($event)" inputId="ownsHouse" binary="true" class="form-control-prime" formControlName="ownsHouse" />
                    </div>
      
                    <div class="col-md-4 mb-3">
                      <label for="houseAreaInSquareFeet">Area in Sq. Feet</label>
                      <p-inputNumber inputId="houseAreaInSquareFeet" class="form-control-prime" formControlName="houseAreaInSquareFeet" mode="decimal" />
                    </div>
                  </div>
                </div>
                <p-divider />
      
                <h5 class="p-t-5">MEMBERSHIP PAYMENT DETAILS</h5>
                <p>
                  Tell us about the payment they made
                </p>
                <p-divider />
                <div class="p-l-10 p-r-10">
                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="joiningFeeAmount">Joining Fees</label>
                      <p-inputNumber inputId="joiningFeeAmount" class="form-control-prime" formControlName="joiningFeeAmount" mode="currency" currency="INR" currencyDisplay="code" locale="en-IN"  />
                    </div>
      
                    <div class="col-md-4 mb-3">
                      <label for="joiningFeePaymentType">Payment Type</label>
                      <p-dropdown id="joiningFeePaymentType" [options]="paymentTypes" formControlName="joiningFeePaymentType" class="form-control-prime" appendTo="body"></p-dropdown>
                    </div>
                  </div>
                </div>
                <p-divider />


                <ng-container>
                  <h5 class="p-t-10">ATTACHMENTS</h5>
                  <p>
                    Application form, Resignation form, Declarations, etc
                  </p>
                  <p-divider />
                  <div class="p-l-10 p-r-10">
                    <input #formAttachments id="formAttachments" multiple="multiple" type="file" (change)="addDocuments($event)" style="display: none;" accept=".jpg, .jpeg,.png,.pdf"/>
                    <div style="text-align: left;display: flex;width: auto;">
                      <div class="p-relative uploaded-images"
                          *ngFor="let attachment of member?.applicationForms;let i = index">
                          <img *ngIf="attachment.contentType !== 'application/pdf'" (click)="openImage(attachment)" style="max-width:100px;max-height:100px" [attr.src]="getImageUrlForAttachment(attachment) | secure | async"
                              alt="Image" />
                          <img *ngIf="attachment.contentType === 'application/pdf'" (click)="downloadAttachment(attachment)" style="max-width:100px;max-height:100px" src="/assets/images/pdf-icon.png" alt="Product Image" />
                          <div class="remove-file" style="padding:0 !important" (click)="removeServerImage(attachment)"><a style="color:red"
                                  href="javascript:void(0)"><i
                                      class="icofont icofont-close"></i></a></div>
                      </div>
                      <div class="p-relative uploaded-images" *ngFor="let preview of fileUploads.previews;let i = index">
                        <img *ngIf="fileUploads.attachments[i].type !== 'application/pdf'" style="max-width:100px;max-height:100px" [src]="preview" alt="Product Image" />
                        <img *ngIf="fileUploads.attachments[i].type === 'application/pdf'" style="max-width:100px;max-height:100px" src="/assets/images/pdf-icon.png" alt="Product Image" />
                        <div class="remove-file" style="padding:0 !important" (click)="removeAttachmentImage(attachment)"><a style="color:red"
                          href="javascript:void(0)"><i
                              class="icofont icofont-close"></i></a></div>
                      </div>
                      <span onclick="document.getElementById('formAttachments').click();"class="add-image add-image-1"><i class="fa fa-plus"></i></span>
                    </div>
                  </div>
                </ng-container>

                <div class="row">
                  <div class="col-md-12 text-center">
                    <button pButton type="submit" class="btn btn-primary">Save Member Details</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ALL PAYMENTS">
      <app-member-payments [memberUUID]="member.uuid"></app-member-payments>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ALL PAYOUTS">
      <app-member-payouts [memberUUID]="member.uuid"></app-member-payouts>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ALL DONATIONS">
      <app-member-donations [memberUUID]="member.uuid"></app-member-donations>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="TIMELINE">
      <app-member-timeline [memberUUID]="member.uuid"></app-member-timeline>
    </p-tabPanel>
    <p-tabPanel *ngIf="editing" header="ASSOCIATION RENEWALS">
        <table class="renewal-table">
          <thead>
          <tr class="table-headers">
            <th>Event</th>
            <th>Date</th>
            <th>Expiry Date</th>
            <th style="text-align: end;">Amount Paid</th>
          </tr>
          </thead>
          <tbody>
            <tr>
              <td>JOINED</td>
              <td>{{member.associationJoinedDate ? moment(member.associationJoinedDate).format("Do MMM YYYY") : "N/A"}}</td>
              <td>N/A</td>
              <td style="text-align: end;">{{member.associationJoiningFees ? member.associationJoiningFees.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) : "N/A" }}</td>
            </tr>
            <tr *ngFor="let renewal of member?.associationRenewals;let i = index">
              <td>RENEWAL</td>
              <td>{{renewal.renewalDate ? moment(renewal.renewalDate).format("Do MMM YYYY") : "N/A"}}</td>
              <td>{{renewal.newExpiryDate ? moment(renewal.newExpiryDate).format("Do MMM YYYY") : "N/A"}}</td>
              <td style="text-align: end;">{{renewal.renewalAmount ? renewal.renewalAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) : "N/A" }}</td>
            </tr>
            <tr class='total'>
              <th style="color:black">Total</th>
              <td class="total-val" colspan="3">{{associationTotalPayment ? associationTotalPayment.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) : "N/A" }}</td>
            </tr>
          </tbody>
        </table>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="editUnitDialog" 
  [style]="{ width: '500px' }" 
  [header]="'Switch Unit?'" 
  (onHide)="editUnitDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    <div class="view-transaction">
      <div class="vt-container">
        <div class="row">
          <div class="col-md-12 mb-3">
            <label for="unitNames">UNIT</label><span class="text-danger">*</span>
            <p-autoComplete id="unitNames" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="selectedUnit" [suggestions]="filteredUnits" (completeMethod)="filterUnits($event)" field="name" [dropdown]="true" class="form-control-prime" appendTo="body">
              <ng-template let-city pTemplate="item">
                  <div class="city-item">
                  <div>{{city.name}}</div>
                  </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton style="background-color: darkred !important;" (click)="editUnitDialog=false" class="btn btn-primary m-r-5">CANCEL</button>
    <button pButton style="background-color: darkgreen !important;" (click)="updateUnit()" class="btn btn-primary m-r-5">UPDATE</button>
  </ng-template>
</p-dialog>

<p-dialog 
  [(visible)]="addFamilyMemberDialog" 
  [style]="{ width: '550px'}" 
  header="Family Member Details" 
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
          <div class="field mb-3">
              <label for="name">Name</label>
              <input type="text" pInputText id="name" [(ngModel)]="selectedFamilyMember.name" required autofocus />
              <small class="p-error" *ngIf="submittedFamilyMemberData && !selectedFamilyMember.name">
                  Name is required.
              </small>
          </div>
          <div class="field mb-3">
              <label for="age">Age</label>
              <p-inputNumber inputId="age" [(ngModel)]="selectedFamilyMember.age"  mode="decimal" [min]="0"  [max]="120" [showButtons]="true" />
          </div>
          <div class="field mb-3">
            <label for="name">Relation</label>
            <p-dropdown id="joiningFeePaymentType" [(ngModel)]="selectedFamilyMember.relation" [options]="familyMemberRelations" appendTo="body" ></p-dropdown>
            <small class="p-error" *ngIf="submittedFamilyMemberData && !selectedFamilyMember.relation">
                Relation is required.
            </small>
          </div>
          <div class="field mb-3">
            <label for="educationalQualification">Qualification</label>
            <p-dropdown id="educationalQualification" [options]="qualifications" [(ngModel)]="selectedFamilyMember.educationalQualification" class="form-control-prime" appendTo="body"></p-dropdown>
        </div>
      </ng-template>

      <ng-template pTemplate="footer">
        <button pButton [raised]="true" (click)="hideDialog()" class="btn btn-primary m-r-5">CANCEL</button>
        <button pButton [raised]="true" (click)="saveFamilyMember()" class="btn btn-primary m-r-5">SAVE / UPDATE</button>
      </ng-template>
</p-dialog>


<p-dialog 
  [(visible)]="deceasedStatusDialog" 
  [style]="{ width: '750px'}" 
  header="Change Status to Deceased?" 
  (onHide)="payoutDetailsBtnEnabled=true;"
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div *ngIf="loadingStatusData" style="width:100%;height:400px;background-color: #fff;justify-content: center;align-items: center;display: flex;">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
        </div>
        <div *ngIf="!loadingStatusData">
          <div class="field mb-3">
            <label for="name">Member Name</label>
            <input pInputText id="name" type="text" [(ngModel)]="deceasedBenefits.name" [disabled]="true" />
          </div>
          <div class="field mb-3">
            <label for="name">Joined Date</label>
            <p-calendar readonlyInput=true  [(ngModel)]="deceasedBenefits.dateJoined" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" [disabled]="true" />
          </div>
          <div class="field mb-3">
            <label for="name">Years in AKMTWWS Society</label>
            <p-inputNumber inputId="yearsOfMembership" [(ngModel)]="deceasedBenefits.experience" mode="decimal" [disabled]="true" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="deductionInPercentage">Service Charge (in %) </label>
            <p-inputNumber (onInput)="payoutDetailsBtnEnabled=true" inputId="deductionInPercentage" [(ngModel)]="deceasedBenefits.deductionInPercentage" mode="decimal" [min]="0"  [max]="10" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="anyOtherDeductions">Any Other Deductions (in ₹) </label>
            <p-inputNumber (onInput)="payoutDetailsBtnEnabled=true" inputId="anyOtherDeductions" [(ngModel)]="deceasedBenefits.anyOtherDeductions" mode="decimal" [min]="0" />
          </div>
          <div class="field mb-3">
            <label for="name">Accident?</label>
            <p-checkbox (onChange)="payoutDetailsBtnEnabled=true" class="new-line" inputId="accident" binary="true" [(ngModel)]="deceasedBenefits.accident" />
          </div>
          <p-divider />
          <div class="field mb-3">
            <button [disabled]="!payoutDetailsBtnEnabled" pButton [raised]="true" (click)="getDeceasedPayoutDetails()" class="btn btn-primary m-r-5">Get Benefit / Payout Details</button>
          </div>

          <p-divider />

          <div *ngIf="deceasedBenefits && deceasedBenefits?.message">
            <table class="benefits-table">
							<tr class="service">
								<td><label for="name">Death Benefit Amount</label></td>
								<td class="tableitem">{{ deceasedBenefits.benefitAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Total Deposit Amount</label></td>
								<td class="tableitem">{{ deceasedBenefits.depositAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Total Subscription Paid</label></td>
								<td class="tableitem">{{ deceasedBenefits.subscriptionAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Service Charge</label></td>
								<td class="tableitem">{{ deceasedBenefits.feeAmount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Other Deductions</label></td>
								<td class="tableitem">{{ deceasedBenefits.deductions?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
                <td><p-divider /></td>
								<td class="tableitem"><p-divider /></td>
              </tr>
              <tr class="service">
								<td><label for="name" style="font-weight: 500;">Total Amount</label></td>
								<td class="tableitem" style="font-weight: 500;">{{ deceasedBenefits.totalAmount.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
            </table>
            <p-messages [value]="[{ severity: 'success', summary: deceasedBenefits.message }]" [enableService]="false" [closable]="false" />
            <p-divider />
            <div class="field mb-3">
              <label for="name">Payment Type</label>
              <p-dropdown id="joiningFeePaymentType" [(ngModel)]="deceasedBenefits.paymentType" [options]="paymentTypes" appendTo="body" ></p-dropdown>
              <small class="p-error" *ngIf="!deceasedBenefits.paymentType">
                  Payment Type is required.
              </small>
            </div>
            <div class="field mb-3">
              <label for="name">Payment Reference No.</label>
              <input pInputText id="refrenceNumber" type="text" [(ngModel)]="deceasedBenefits.paymentReferenceNumber" />
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton [raised]="true" (click)="deceasedStatusDialog=false;" class="btn btn-primary m-r-5">Cancel</button>
        <button pButton [disabled]="!(deceasedBenefits && deceasedBenefits?.totalAmount)" [raised]="true" (click)="changeStatusToDeceased()" class="btn btn-primary m-r-5">Change Status</button>
    </ng-template>
</p-dialog>

<p-dialog 
  [(visible)]="deletedStatusDialog" 
  [style]="{ width: '650px'}" 
  [header]="retirementBenefits.retired ? 'Change Status to Retired?' : 'Change Status to Expelled?'" 
  (onHide)="payoutDetailsBtnEnabled=true;"
  [modal]="true" 
  styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div *ngIf="loadingStatusData" style="width:100%;height:400px;background-color: #fff;justify-content: center;align-items: center;display: flex;">
          <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" ariaLabel="loading" />
        </div>
        <div *ngIf="!loadingStatusData">
          <div class="field mb-3">
            <label for="name">Member Name</label>
            <input pInputText id="name" type="text" [(ngModel)]="retirementBenefits.name" [disabled]="true" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="name">Joined Date</label>
            <p-calendar readonlyInput=true  [(ngModel)]="retirementBenefits.dateJoined" [showIcon]="true" [iconDisplay]="'input'" inputId="buttondisplay" dateFormat="dd MM yy" [disabled]="true" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="yearsOfMembership">Years in AKMTWWS Society</label>
            <p-inputNumber inputId="yearsOfMembership" [(ngModel)]="retirementBenefits.experience" mode="decimal" [disabled]="true" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="deductionInPercentage">Service Charge (in %) </label>
            <p-inputNumber (onInput)="payoutDetailsBtnEnabled=true" inputId="deductionInPercentage" [(ngModel)]="retirementBenefits.deductionInPercentage" mode="decimal" [min]="0"  [max]="10" />
          </div>
          <div class="field mb-3" style="max-width:250px">
            <label for="anyOtherDeductions">Any Other Deductions (in ₹) </label>
            <p-inputNumber (onInput)="payoutDetailsBtnEnabled=true" inputId="anyOtherDeductions" [(ngModel)]="retirementBenefits.anyOtherDeductions" mode="decimal" [min]="0" />
          </div>
          <p-divider />
          <div class="field mb-3">
            <button [disabled]="!payoutDetailsBtnEnabled" pButton [raised]="true" (click)="getExpelledPayoutDetails()" class="btn btn-primary m-r-5">Get Payout Details</button>
          </div>

          <p-divider />

          <div *ngIf="retirementBenefits && retirementBenefits?.message">
            <table class="benefits-table">
              <tr class="service">
								<td><label for="name">Total Deposit Amount</label></td>
								<td class="tableitem">{{ retirementBenefits.depositAmount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Total Subscription Paid</label></td>
								<td class="tableitem">{{ retirementBenefits.subscriptionAmount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Service Charge</label></td>
								<td class="tableitem">{{ retirementBenefits.feeAmount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
								<td><label for="name">Other Deductions</label></td>
								<td class="tableitem">{{ retirementBenefits.deductions?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
              <tr class="service">
                <td><p-divider /></td>
								<td class="tableitem"><p-divider /></td>
              </tr>
              <tr class="service">
								<td><label for="name" style="font-weight: 500;">Total Amount</label></td>
								<td class="tableitem" style="font-weight: 500;">{{ retirementBenefits.totalAmount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',}) }}</td>
							</tr>
            </table>
            <p-messages [value]="[{ severity: 'success', summary: retirementBenefits.message }]" [enableService]="false" [closable]="false" />
            <p-divider />
            <div class="field mb-3">
              <label for="name">Payment Type</label>
              <p-dropdown id="joiningFeePaymentType" [(ngModel)]="retirementBenefits.paymentType" [options]="paymentTypes" appendTo="body" ></p-dropdown>
              <small class="p-error" *ngIf="!retirementBenefits.paymentType">
                  Payment Type is required.
              </small>
            </div>
            <div class="field mb-3">
              <label for="name">Payment Reference No.</label>
              <input pInputText id="refrenceNumber" type="text" [(ngModel)]="retirementBenefits.paymentReferenceNumber" />
            </div>
            <!-- <input #formAttachments id="formAttachments" multiple="multiple" type="file" (change)="addDocuments($event)" style="display: none;" accept="image/*"/>
            <div class="field mb-3">
              <button type="button" class="btn btn-outline-primary m-t-10 m-b-10" onclick="document.getElementById('formAttachments').click();">
                <i class="p-r-10 fa fa-plus"></i>
                <span>Application Form / Other Documents</span>
              </button>
            </div> -->
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton [raised]="true" (click)="deletedStatusDialog=false;" class="btn btn-primary m-r-5">Cancel</button>
        <button pButton [disabled]="!(retirementBenefits && retirementBenefits?.message)" [raised]="true" (click)="changeStatusToExpelled()" class="btn btn-primary m-r-5">Change Status</button>
    </ng-template>
</p-dialog>

<ng-template #template>
  <div class="modal-body" style="display: flex;justify-content: center;max-height:80vh">
      <img [attr.src]="getImageUrlForAttachment(selectedAttachment) | secure | async">
  </div>
</ng-template>
<p-table
  #dt
  [value]="allPayments"
  [paginator]="true"
  [rows]="10"
  [totalRecords]="totalCount"
  selectionMode="single"
  [lazy]="true"
  [reorderableColumns]="true"
  [(selection)]="selectedPayment" 
  (onRowSelect)="onRowSelect($event)" 
  [rowsPerPageOptions]="[10, 20, 50]"
  (onLazyLoad)="loadData($event)"
  scrollHeight="calc(100vh - 405px)"
  [scrollable]="true" 
  styleClass="mt-3"
  dataKey="id"
>
<ng-template pTemplate="caption">
  <div class="flex flex-wrap justify-content-end gap-2">
      <p-button pRipple class="btn-rounded" label="Refresh Data" icon="pi pi-refresh" text (onClick)="refreshList()" />
  </div>
</ng-template>
  <!-- Expand Button Column -->
  <ng-template pTemplate="header">
    <tr>
      <th pFrozenColumn></th>
      <th pFrozenColumn>Payment Date</th>
      <th style="text-align:right">Total Deposit Paid</th>
      <th style="text-align:right">Total Subscription Paid</th>
      <th style="text-align:right">Total Amount Paid</th>
      <th style="text-align:left">Paid By</th>
      <th style="text-align:left">Confirmed By</th>
      <th pFrozenColumn>Reference #</th>
      <th pFrozenColumn>Status</th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-payment>
    <tr [pSelectableRow]="payment">
      <td pFrozenColumn></td>
      <td pFrozenColumn>{{ moment(payment.unitPayment?.paymentDate).format("Do MMM YYYY") }}</td>
      <td style="text-align:right">{{ payment.depositAmount | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ payment.weeklySubscriptionAmount | currency:'INR':'symbol'}}</td>
      <td style="text-align:right">{{ payment.totalAmount | currency:'INR':'symbol'}}</td>
      <td >{{ payment.unitPayment?.createdBy?.name }}</td>
      <td>{{ payment.unitPayment?.confirmedBy?.name }}</td>
      <td>{{ payment.unitPayment?.transactionReferenceNumber }}</td>
      <td [ngStyle]="{'background-color' : getBackgroundColor(payment.unitPayment), 'color':getColor(payment.unitPayment)}">{{ payment.unitPayment?.status.toUpperCase() }}</td>
      <td>
        <div class="flex align-items-center justify-content-center gap-2">
          <!-- <button style="background-color: white !important;color:#000" pButton [raised]="true" (click)="viewTransaction(payment)" class="btn btn-primary m-r-5">VIEW</button> -->
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr  style="height:calc(100vh - 405px)">
        <td colspan="11" style="text-align: center;">There are no member payments</td>
    </tr>
  </ng-template>
</p-table>

<p-dialog 
  autoZIndex="false"
  baseZIndex="1000"
  [(visible)]="viewDialog" 
  [style]="{ width: '900px' }" 
  [header]="'Week Payment Details'" 
  (onHide)="viewDialog=false;"
  [modal]="true" 
  styleClass="p-fluid">
  
  <ng-template pTemplate="content">
    
    <div *ngIf="selectedPayment" class="p-b-20">
      <p-card header="Week Payments" subheader="You can see the weeks cleared using this payment.">
        <ng-container *ngFor="let societyUnitMemberPayment of selectedPayment.societyUnitMemberPayments; let index = index">
          <div class="row">
            <div class="col-md-3">
              <label>WEEK - {{ societyUnitMemberPayment.weekNumber }}</label>
            </div>
            <div class="col-md-3">
              <label>DEPOSIT - {{ societyUnitMemberPayment.depositAmount | currency:'INR':'symbol' }}</label>
            </div>
            <div class="col-md-3">
              <label>SUBSCRIPTION - {{ societyUnitMemberPayment.weeklySubscriptionAmount | currency:'INR':'symbol' }}</label>
            </div>
            <div class="col-md-3">
              <label>TOTAL - {{ societyUnitMemberPayment.totalAmount | currency:'INR':'symbol' }}</label>
            </div>
          </div>
        </ng-container>
    </p-card>
    </div>
  </ng-template>
</p-dialog>
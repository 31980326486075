import { state } from '@angular/animations';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, lastValueFrom, map } from 'rxjs';
import Helper from 'src/app/helper/Helper';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IS_UPLOAD_REQUEST, MultipleFileUpload } from 'src/app/types/types';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth-service.service';
import { MenuItem } from 'primeng/api';
import { SplitButton } from 'primeng/splitbutton';
import { error } from 'console';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')


@Component({
  selector: 'app-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss']
})
export class UtilitiesComponent implements OnInit {

  selectedFile
  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private fb: FormBuilder,private toastrService: ToastrService,
    private authService: AuthService, protected modalService: NgbModal, protected appService: AppService) { }

  ngOnInit() {
    this.appService.setTitle("UTILITIES")
    this.appService.setMenuItems([{label:'SETTINGS',disabled:true,icon:"pi pi-cog"}, {label:'UTILITIES',disabled:true,icon:"pi pi-wrench"}])
  }

  downloadSample() {
    // Create an anchor element dynamically
    const link = document.createElement('a');
    link.href = '/assets/xls/Association_Mem-Renewal_Template.xlsx'; // Path to your file in the assets folder
    link.download = 'Association_Mem-Renewal_Template.xlsx'; // Name for the downloaded file
    document.body.appendChild(link); // Append the link to the body
    link.click(); // Trigger the click event
    document.body.removeChild(link); // Remove the link after the download
  }

  processFile(){
    const formData: FormData = new FormData();  
    if( this.selectedFile ){
      formData.append("uploadedFile",this.selectedFile);
    }

    this.httpClient.post(`${environment.serverUrl}/helper/import-renewal-file`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','All renewals updated successfully!','success');
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });
  }

  async addFile(event){
    if (event.target.files.length === 0)
      return;
    this.selectedFile = event.target.files[0];
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Menu } from '../../services/nav-service.service';
import { FlytAuthGuard } from '../../guards/flyt-auth-guard.guard';
import {Router, RouterModule} from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports:[
    RouterModule
  ]
})
export class AdminMenuModule { 

  constructor(private router: Router){}
  
  public static MENUITEMS: Menu[] = [
		  // {
      //   title: 'DASHBOARD', icon: 'home', type: 'link', active: true,path: 'dashboard',
      //   route:{
      //     path: 'dashboard',
      //     data: {
      //       breadcrumb: {
      //         label: 'Dashboard',
      //       },
      //       role: 'Admin, Data Entry',
      //       animation: 'slideInAnimation'
      //     },
      //     children: []
      //   },
      // },
      {
        title: 'ALL MEMBERS', icon: 'pi pi-users', type: 'link', active: true,path: 'members',
        route:{
          path: 'members',
          data: {
            breadcrumb: {
              label: 'ALL MEMBERS',
            },
            role: 'Admin, Data Entry',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'PAYMENTS', icon: 'pi pi-money-bill', type: 'sub', active: false, path: 'members',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"PAYMENTS",
                breadcrumb: {
                  label: "ALL PAYMENTS",
                  info: 'pi pi-money-bill'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'WEEKLY PAYMENTS', icon: 'pi pi-indian-rupee', type: 'link', active: false, path: 'payments',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYMENTS",
                    breadcrumb: {
                      label: "ALL PAYMENTS",
                      info: 'pi pi-wallet'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'ALL TRANSACTIONS', icon: 'pi pi-indian-rupee', type: 'link', active: false, path: 'payments/all-transactions',
            route:{
              children: [
                {
                  data: {
                    title:"ALL TRANSACTIONS",
                    breadcrumb: {
                      label: "ALL TRANSACTIONS",
                      info: 'pi pi-indian-rupee'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'ALL PAYOUTS', icon: 'pi pi-indian-rupee', type: 'link', active: false, path: 'payments/all-payouts',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYOUTS",
                    breadcrumb: {
                      label: "ALL PAYOUTS",
                      info: 'pi pi-indian-rupee'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
        ]
      },
      // {
      //   title: 'ALL DONATIONS', icon: 'pi pi-wallet', type: 'link', active: true,path: 'donations',
      //   route:{
      //     path: 'donations',
      //     data: {
      //       breadcrumb: {
      //         label: 'ALL DONATIONS',
      //       },
      //       role: 'Admin, Data Entry',
      //       animation: 'slideInAnimation'
      //     },
      //     children: []
      //   },
      // },
      {
        title: 'ALL UNITS', icon: 'pi pi-sitemap', type: 'link', active: true,path: 'units',
        route:{
          path: 'donations',
          data: {
            breadcrumb: {
              label: 'ALL UNITS',
            },
            role: 'Admin, Data Entry',
            animation: 'slideInAnimation'
          },
          children: []
        },
      },
      {
        title: 'REPORTS', icon: 'pi pi-print', type: 'sub', active: false, path: 'settings',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"SETTINGS",
                breadcrumb: {
                  label: "SETTINGS",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'ALL MEMBERS', icon: 'pi pi-users', type: 'link', active: false, path: 'reports/all-members',
            route:{
              children: [
                {
                  data: {
                    title:"ALL MEMBERS",
                    breadcrumb: {
                      label: "ALL MEMBERS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'MEMBER CHILDREN', icon: 'pi pi-user-edit', type: 'link', active: false, path: 'reports/all-school-children',
            route:{
              children: [
                {
                  data: {
                    title:"ALL SCHOOL GOING CHILDREN",
                    breadcrumb: {
                      label: "ALL SCHOOL CHILDREN",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          }
        ]
      },
      {
        title: 'SETTINGS', icon: 'pi pi-cog', type: 'sub', active: false, path: 'settings',
        route:{
          children: [
            {
              pathMatch: 'full',
              data: {
                title:"SETTINGS",
                breadcrumb: {
                  label: "SETTINGS",
                  info: 'icofont icofont-files'
                },
                role: 'Administrator, Data Entry',
                animation: 'slideInAnimation'
              }
            },
          ]
        },
        children:[
          {
            title: 'ALL PAYOUT EVENTS', icon: 'pi pi-receipt', type: 'link', active: false, path: 'settings/all-payout-events',
            route:{
              children: [
                {
                  data: {
                    title:"ALL PAYOUT EVENTS",
                    breadcrumb: {
                      label: "ALL PAYOUT EVENTS",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          // {
          //   title: 'ALL DONATION TYPES', icon: 'pi pi-receipt', type: 'link', active: false, path: 'settings/all-donation-types',
          //   route:{
          //     children: [
          //       {
          //         data: {
          //           title:"ALL DONATION TYPES",
          //           breadcrumb: {
          //             label: "ALL DONATION TYPES",
          //             info: 'icofont icofont-files'
          //           },
          //           role: 'Administrator, Data Entry',
          //           animation: 'slideInAnimation'
          //         }
          //       }
          //     ]
          //   }
          // },
          {
            title: 'VIDEO MANAGER', icon: 'pi pi-youtube', type: 'link', active: false, path: 'settings/videos',
            route:{
              children: [
                {
                  data: {
                    title:"VIDEO MANAGER",
                    breadcrumb: {
                      label: "VIDEO MANAGER",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
          {
            title: 'UTILITIES', icon: 'pi pi-wrench', type: 'link', active: false, path: 'settings/utilities',
            route:{
              children: [
                {
                  data: {
                    title:"UTILITIES",
                    breadcrumb: {
                      label: "UTILITIES",
                      info: 'icofont icofont-files'
                    },
                    role: 'Administrator, Data Entry',
                    animation: 'slideInAnimation'
                  }
                }
              ]
            }
          },
        ]
      },
	]
}

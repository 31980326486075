import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-list-all-units',
  templateUrl: './list-all-units.component.html',
  styleUrls: ['./list-all-units.component.scss']
})
export class ListAllUnitsComponent implements OnInit {

  @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  @ViewChild('dt') dataTable: Table;

  moment = moment
  environment = environment
  
  selectedUnit = undefined
  allUnits: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  
  districts: any[] = [];
  expandedRows = {}

  editing = false

  totalAmountReceived = 0

  protected fileUploads : MultipleFileUpload = new MultipleFileUpload()

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder,private router: Router,
    private appService: AppService
  ) { }

  ngOnInit() {

    this.appService.setTitle("VIEW ALL UNITS")
    this.appService.setMenuItems([{label:'ALL UNITS', routerLink: '/units',icon:"pi pi-sitemap", disabled:true}])

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/districts/all`),
     ]).pipe(
      map(([districts]) => { 
        this.districts = districts.data
      })
    ))
  }

  loadUnits(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'ASC' : 'DESC', // Sorting direction
    };

    // Apply filters dynamically if they exist
    if (event.filters.name && event.filters.name.value) {
      params.name = event.filters.name.value;
    }
    if (event.filters.unitNumber && event.filters.unitNumber.value) {
      params.unitNumber = event.filters.unitNumber.value;
    }

    if (event.filters.districtName && event.filters.districtName.value) {
      params.districts = event.filters.districtName.value.map(a => a.uuid).join(",")
    }
    
    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/units/crm`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allUnits = result.data;
        this.allUnits.map (mem =>{
          
        })
        this.totalRecords = result.count;
        this.totalAmountReceived = result.totalAmountReceived
        
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  addNewUnit(){
    this.router.navigate(['units','add-unit'])
  }

  onRowSelect(event: any) {
    this.onRowExpand(event)
  }

  onRowUnselect(event: any) {
    this.expandedRows = {}
  }
  onRowEditInit(member){
    this.router.navigate(['units','edit-unit',member.uuid])
  }

  onRowExpand(event:any) {

    this.expandedRows = {}
    const unit = event.data;
    if (unit.members) {
      this.expandedRows[unit.id] = true;
      return;
    }

    this.httpClient.get(`${environment.serverUrl}/units/members?unitUUID=${unit.uuid}`).subscribe(
      (result:any) => {
        unit.members = result.data;
        this.expandedRows[unit.id] = true;
      },
      (error) => {
        console.error('Error loading unit members:', error);
      }
    );
  }

  refreshList(){
    if (this.dataTable) {
      this.loadUnits(this.dataTable.createLazyLoadMetadata());
   }
  }

  getMemberRowColor(member){
    if( member.status === 'Active'){
      return '#000'
    }
    else if( member.status === 'Suspended'){
      return '#FF0000'
    }
    else if( member.status === 'No Unit'){
      return '#FF0000'
    }
    else if( member.status === 'Soft-Deleted' || member.status === 'Soft-Deceased'){
      return '#FF0000'
    }
    else if( member.status === 'Deleted' || member.status === 'Deceased'){
      return '#FF0000'
    }
  }
  
  getRowColor(unit){
    if( unit.active ){
      return '#009688'
    }
    return '#FF00000'
  }
}

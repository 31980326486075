import { Attachment, IS_UPLOAD_REQUEST, MultipleFileUpload } from './../../../types/types';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, ElementRef, HostListener, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Table } from 'primeng/table';
import { lastValueFrom, combineLatest, map } from 'rxjs';
import { ColumnFilter } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Helper from 'src/app/helper/Helper';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import { AppService } from 'src/app/app.service';
declare var require
const Swal = require('sweetalert2')

@Component({
  selector: 'app-list-all-payouts',
  templateUrl: './list-all-payouts.component.html',
  styleUrls: ['./list-all-payouts.component.scss']
})
export class ListAllPayoutsComponent implements OnInit {
  @ViewChildren('formField', { read: ElementRef }) formFields!: QueryList<ElementRef>;
  @ViewChild('template') imageTemplateRef: TemplateRef<any>;
  @ViewChild('dt') dataTable: Table;

  moment = moment
  environment = environment
  
  allPayouts: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  
  statuses : any[] = []
  payoutEvents: any[] = [];
  units: any[] = [];
  paymentTypes: any[] = []
  viewTransactionDialog = false
  selectedPayout = undefined
  selectedPayoutAttachments : any[] = []
  selectedAttachment = undefined
  payoutForm: FormGroup = undefined; // Define the form group
  filteredMembers = []
  editing = false
  protected fileUploads : MultipleFileUpload = new MultipleFileUpload()

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, protected modalService: NgbModal,private fb: FormBuilder, private appService: AppService) { }

  ngOnInit() {

    this.appService.setTitle("VIEW ALL PAYOUTS")
    this.appService.setMenuItems([{label:'ALL PAYOUTS', routerLink: '/all-payouts',icon:"pi pi-indian-rupee", disabled:true}])

    lastValueFrom(combineLatest([
      this.httpClient.get<any>(`${environment.serverUrl}/payouts/payout-events/all`),
      this.httpClient.get<any>(`${environment.serverUrl}/units/all`),
     ]).pipe(
      map(([payoutEvents, units]) => { 
        this.payoutEvents = payoutEvents.data
        this.units = units.data
      })
    ))

    this.statuses = [
      { label: 'Approved', value: 'Paid' },
      { label: 'Declined', value: 'Declined' },
      { label: 'Requested', value: 'Requested' },
    ];
    
    this.paymentTypes = [
      { label: 'Cash', id: 'Cash' },
      { label: 'Credit Card', id: 'CreditCard' },
      { label: 'Debit Card', id: 'DebitCard' },
      { label: 'UPI', id: 'UPI' },
      { label: 'PayTM', id: 'PayTM' },
      { label: 'Bank Transfer', id: 'BankTransfer' },
      
    ];
  }

  loadTransactions(event: any) {
    this.loading = true;

    // Prepare the parameters for pagination, sorting, and filtering
    const params: any = {
      take: event.rows, // Number of records to take
      skip: event.first, // Starting point
      order: event.sortField || 'id', // Sorting field
      orderDir: event.sortOrder === 1 ? 'DESC' : 'ASC', // Sorting direction
    };

    // Apply filters dynamically if they exist
    if (event.filters.name && event.filters.name.value) {
      params.name = event.filters.name.value;
    }
    if (event.filters.transactionReferenceNumber && event.filters.transactionReferenceNumber.value) {
      params.transactionReferenceNumber = event.filters.transactionReferenceNumber.value;
    }
    if (event.filters.unitName && event.filters.unitName.value) {
      params.units = event.filters.unitName.value.map(a => a.uuid).join(",")
    }
    if (event.filters.status && event.filters.status.value) {
      params.status = event.filters.status.value;
    }

    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/payouts/`, { params: httpParams }).subscribe(
      (result:any) => {
        this.allPayouts = result.data;
        this.allPayouts.map (mem =>{
          
        })
        this.totalRecords = result.count;
        
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching Unit Payments:', error);
        this.loading = false;
      }
    );
  }

  addNewPayout(){
    this.editing = false
    this.fileUploads = new MultipleFileUpload()
    this.selectedPayout = undefined
    this.selectedPayoutAttachments = []
    this.payoutForm = this.fb.group({
      member:[''],
      memberSelected:['',Validators.required],
      unitName: ['', Validators.required],
      payoutEvent: ['', Validators.required],
      amountPaid: ['', Validators.required],
      paymentReferenceNumber: [''],
      requestedDate: [undefined, Validators.required],
      paymentDate: [undefined, Validators.required],
      paymentType: [undefined, Validators.required],
      reason: [''],
    })
    this.viewTransactionDialog = true
    
  }

  onRowSelect(event: any) {
    this.viewTransaction(event.data)
  }

  viewTransaction(payout){
    this.editing = true
    this.selectedPayout = payout
    this.payoutForm = this.fb.group({
      member:[payout.member],
      memberSelected:[true,Validators.required],
      unitName: [payout.member.unit.name, Validators.required],
      payoutEvent: [payout.payoutEvent, Validators.required],
      amountPaid: [payout.amountPaid, !(payout.status === 'Declined') ? Validators.required : undefined],
      paymentReferenceNumber: [payout.paymentReferenceNumber],
      requestedDate: [this.selectedPayout.requestedDate ? new Date(this.selectedPayout.requestedDate): undefined, Validators.required],
      paymentDate: [this.selectedPayout.paymentDate ? new Date(this.selectedPayout.paymentDate): undefined, !(payout.status === 'Declined') ? Validators.required : undefined],
      paymentType: [this.selectedPayout.paymentType, !(payout.status === 'Declined') ? Validators.required : undefined],
      reason: [this.selectedPayout.reasonForDeclining],
    })
    this.fileUploads = new MultipleFileUpload()
    this.selectedPayoutAttachments = this.selectedPayout.attachments
    this.viewTransactionDialog = true

  }

  savePayoutDetails(){

    if (!this.payoutForm.valid) {
      this.payoutForm.markAllAsTouched();
      this.focusFirstInvalidControl()
      Helper.showMessageAlert('Error',`Please fill in all the mandatory fields before saving.`,'error');
      return;
    }

    const formData = this.getFormData()
    
    this.httpClient.post(`${environment.serverUrl}/payouts`, formData,{
      context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
      }).subscribe({
        next: (v:any) => {
          console.log(v)
          Helper.showMessageAlert('Success','Payout details added successfully!','success');
          this.viewTransactionDialog = false
          this.loadTransactions(this.dataTable.createLazyLoadMetadata())
        },
        error: (e) => {
          console.error(e);
          Helper.processError(e);
        },
        complete: () => console.info('complete')
      });
  }

  onMemberSelected(e){
    this.payoutForm.patchValue({
      member: e.value,
      unitName: e.value?.unit ? e.value?.unit.name : "",
      memberSelected: true
    });
  }

  filterMembers(event) {

    let query = event.query;
    const params: any = {
      take: 10, // Number of records to take
      skip: 0, // Starting point
      order: 'name', // Sorting field
      orderDir: 'ASC', // Sorting direction
    };
    params.name = query.toLowerCase();
    const httpParams = new HttpParams({ fromObject: params });

    this.httpClient.get(`${environment.serverUrl}/members`, {params: httpParams},).subscribe({
      next: (result:any) => {
        this.filteredMembers = result.data
      },
      error: (e) => {
        console.error(e);
        Helper.processError(e);
      },
      complete: () => console.info('complete')
    });
  }

  approveSelectedPayout(){
    
    if (!this.payoutForm.valid) {
      this.payoutForm.markAllAsTouched();
      this.focusFirstInvalidControl()
      Helper.showMessageAlert('Error',`Please fill in all the mandatory fields before proceeding.`,'error');
      return;
    }

    Helper.showConfirmationAlert(`Are you sure?`,`You want to Approve this Accident claim?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        
        const formData = this.getFormData()

        this.httpClient.post(`${environment.serverUrl}/payouts/request/approve`, formData,{
          context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
          }).subscribe({
            next: (v:any) => {
              Helper.showMessageAlert('Success','Payment successfully confirmed!','success');
              this.viewTransactionDialog = false
              this.loadTransactions(this.dataTable.createLazyLoadMetadata());
            },
            error: (e) => {
              console.error(e);
              Helper.processError(e);
            },
            complete: () => console.info('complete')
          });
      }
    })
  }

  declineSelectedPayout(){

    Helper.showConfirmationAlert(`Are you sure?`,`You want to DECLINE this Accident claim?`,`question`,"Yes","No")
    .subscribe((result) => {
      if( result ){
        const formData = this.getFormData()
        this.httpClient.post(`${environment.serverUrl}/payouts/request/decline`, formData,{
          context:new HttpContext().set(IS_UPLOAD_REQUEST, true)
          }).subscribe({
          next: (v:any) => {
            Helper.showMessageAlert('Success','Payment successfully declined!','success');
            this.viewTransactionDialog = false
            this.loadTransactions(this.dataTable.createLazyLoadMetadata());
          },
          error: (e) => {
            console.error(e);
            Helper.processError(e);
          },
          complete: () => console.info('complete')
        });
      }
    })
  }

  getFormData(){
    const formData: FormData = new FormData();    
    for (const field in this.payoutForm.controls) { 
      const control = this.payoutForm.get(field);
      if( control.value && control.value != null){
        if( field === "member"){
          formData.append("memberId",control.value.id)
          continue
        }
        else if( field === "payoutEvent"){
          formData.append("payoutEventId",control.value.id)
          continue
        }
        formData.append(field,control.value)
      }
    }
    if( this.selectedPayout && this.selectedPayout.uuid ){
      formData.append("payoutUUID",this.selectedPayout.uuid)
    }

    if( this.fileUploads && this.fileUploads.attachments.length > 0 ){
      this.fileUploads.attachments.forEach((file) => { formData.append('payoutRequestImages', file); });
    }
    return formData
  }


  refreshList(){
    if (this.dataTable) {
      this.loadTransactions(this.dataTable.createLazyLoadMetadata());
   }
  }
  
  getBackgroundColor(payment){
    if( payment.status === 'Paid'){
      return '#009688'
    }
    else if( payment.status === 'Requested'){
      return '#BA8E23'
    }
    else if( payment.status === 'Declined'){
      return '#FF0000'
    }
  }

  getColor(payment){
    if( payment.status === 'Paid'){
      return '#FFF'
    }
    else if( payment.status === 'Requested'){
      return '#FFF'
    }
    else if( payment.status === 'Declined'){
      return '#FFF'
    }
  }

  getImageUrlForAttachment(attachment){
    return `${environment.serverUrl}/file/download?path=${attachment.objectName}`
  }

  openImage(attachment){
    this.selectedAttachment = attachment
    const modalRef = this.modalService.open(this.imageTemplateRef, {backdropClass: 'light-blue-backdrop', centered:true,size:'xl',keyboard:true});
  }

    getTimestamp(){
    return Date.now()
  }

  private focusFirstInvalidControl() {
    const formControls = this.payoutForm.controls;

    // Iterate through the controls using Object.keys
    for (const key of Object.keys(formControls)) {
      const control = this.payoutForm.get(key);

      // If the control is invalid, find the corresponding input element and focus it
      if (control?.invalid) {
        const invalidControl = this.formFields.find((element) => {
          const inputElement = element.nativeElement.querySelector('input');
          // Check if the input element's name matches the key or fallback to nativeElement.name
          return inputElement?.name === key || element.nativeElement.name === key;
        });

        if (invalidControl) {
          const inputElement = invalidControl.nativeElement.querySelector('input');

          // Focus the input element if it exists, otherwise fallback to the native element
          if (inputElement) {
            inputElement.focus();
          } else {
            invalidControl.nativeElement.focus();
          }
        }
        break; // Focus only on the first invalid control
      }
    }
  }

  downloadAttachment(attachment){
    this.httpClient.get(this.getImageUrlForAttachment(attachment), { responseType: 'blob' })
    .subscribe({
      next: (blob) => {
        saveAs(blob, attachment.originalFileName); // Triggers the "Save As" dialog
      },
      error: (error: HttpErrorResponse) => {
        console.error('Download failed:', error);
      }
    } )
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
  removeServerImage(attachment){
    Swal.fire({
      title: 'Are you sure you want to delete the attachment?',
      text: "Remember: This action cannot be reversed,",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ff7c92',
      confirmButtonText: 'Yes',
      cancelButtonText:'No'
    }).then((result) => {
      if( result.value ){
        let params = {"path":attachment.objectName}
        this.httpClient.delete(`${environment.serverUrl}/payouts/${this.selectedPayout.uuid}/attachments?attachmentId=${attachment.uuid}`)
        .subscribe({
          next: (result) =>{
            this.selectedPayoutAttachments = this.selectedPayoutAttachments.filter( object => object.id !== attachment.id )
            this.loadTransactions(this.dataTable.createLazyLoadMetadata())
          },
          error:(err) =>{
            Helper.processError(err)
          }
        })
      }
    })
  }

  removeAttachmentImage(i){
    this.fileUploads.attachments.splice(i,1)
    this.fileUploads.progressInfos.splice(i,1)
    this.fileUploads.previews.splice(i,1)
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  async addDocuments(event){
    if (event.target.files.length === 0)
      return;
    
    for (var i = 0; i <= event.target.files.length - 1; i++) {
      
      const reader = new FileReader();
      var that = this

      reader.onload = (function(file){
        return function(e){
          that.fileUploads.attachments.push(file);
          that.fileUploads.progressInfos.push( { value: 0, fileName: file.name,size:Helper.humanFileSize(file.size), type:'' } );
          that.fileUploads.previews.push(e.target.result);
        }
      })(event.target.files[i])

      reader.readAsDataURL(event.target.files[i]);
    }
  }
}
